<template>
  <div
    class="relative bg-red-50 flex justify-center max-h-screen overflow-hidden"
  >
    <!-- <div
      v-if="this.loading == true"
      class="absolute overflow-y-hidden max-h-2xl flex items-center justify-center w-full h-full border border-gray-200 rounded-lg bg-gray-50 bg-opacity-75 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    >
      <div role="status flex justify-center ">
        <span class="flex justify-center">
          <svg
            aria-hidden="true"
            class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </span>
        <span class="text-4xl flex justify-center py-8">Loading...</span>
        <span class="text-xl flex justify-center py-2"
          >Please wait, it may take a few minutes</span
        >
      </div>
    </div> -->
    <div
      v-if="this.closed == true"
      class="absolute overflow-y-hidden max-h-2xl flex items-center justify-center w-full h-full border border-gray-200 rounded-lg bg-gray-50 bg-opacity-75 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    >
      <div role="status flex justify-center ">
        <span class="flex justify-center">
          <svg
            aria-hidden="true"
            class="flex-shrink-0 inline w-10 h-10 mr-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </span>
        <span class="text-4xl flex justify-center py-8"
          >The application for acceleration program is closed</span
        >
        <span class="text-xl flex justify-center py-2">Thank you!</span>
      </div>
    </div>
    <div class="w-full md:w-1/2 flex flex-col justify-center p-2 pb-8">
      <div class="">
        <img src="../assets/Acceleration.jpg" class="rounded-md" alt="" />
      </div>

      <div
        v-if="stage === 1 || stage === 2"
        class="text-left bg-white rounded-md border-t-8 border-red-500 mb-8 mt-4"
      >
        <div class="space-y-4 p-6">
          <h1 class="text-4xl">
            <span class="font-bold"> Acceleration program</span> - application
            form
          </h1>
          <p>
            The "<span class="font-bold">Acceleration</span>" program is a
            program that supports startup teams with a minimum viable product
            (MVP) to develop and become investment-ready.
          </p>
          <p>
            At the end of the 5-month Acceleration program, as a result of the
            support of our experts, mentorship, back-end support, sales
            channels, and talent pool, your sales and marketing channels and
            your presentation will be fully ready to meet with investors 🤩
          </p>
          <p>📌 The "Acceleration" program will last for 5 months.</p>
        </div>
      </div>

      <div class=" " v-if="stage === 1">
        <form class="space-y-4" @submit.prevent="nextStage">
          <div
            class="form-group bg-white w-full p-8 rounded-md text-left space-y-4 border border-gray-200"
          >
            <label for="teamName"
              >Team Name<span class="text-red-500">*</span>
            </label>
            <input
              type="text"
              class="form-control block border-b-2 w-72 md:w-96 h-12 z-20 text-sm text-gray-900 bg-white outline-none focus:outline-none focus:border-red-500 focus:ring-red-500"
              id="teamName"
              v-model="teamName"
              required
            />
          </div>
          <div
            class="form-group bg-white w-full p-8 rounded-md text-left space-y-4 border border-gray-200"
          >
            <label for="fullName"
              >Full Name of contact person<span class="text-red-500"
                >*</span
              ></label
            >
            <input
              type="text"
              class="form-control block border-b-2 w-72 md:w-96 h-12 z-20 text-sm text-gray-900 bg-white focus:outline-none focus:border-red-500 focus:ring-red-500"
              id="fullName"
              v-model="fullName"
              required
            />
          </div>
          <div
            class="form-group bg-white w-full p-8 rounded-md text-left space-y-4 border border-gray-200 flex flex-col"
          >
            <label for="phoneNumber"
              >Phone number<span class="text-red-500">*</span></label
            >
            <small class="text-gray-500">
              Please include your country code (e.g. +994)
            </small>
            <input
              type="text"
              class="form-control block border-b-2 w-72 md:w-96 h-12 z-20 text-sm text-gray-900 bg-white focus:outline-none focus:border-red-500 focus:ring-red-500"
              id="phoneNumber"
              v-model="phoneNumber"
              required
            />
          </div>
          <div class="align-baseline text-right">
            <button
              @click.prevent="nextStage"
              class="bg-white px-4 rounded-md text-red-500 shadow-md"
            >
              <p class="px-2 py-1.5 font-bold">Next</p>
            </button>
          </div>
        </form>
      </div>
      <div v-if="stage === 2">
        <form
          enctype="multipart/form-data"
          @submit.prevent="submitForm;"
          class="space-y-8"
        >
          <div
            class="form-group bg-white w-full p-8 rounded-md text-left space-y-4 border border-gray-200"
          >
            <label for="pitchDeck"
              >Please, attach your <span class="font-bold"> Pitch Deck:</span>
              <span class="text-red-500">*</span></label
            >
            <p>
              Pitch deck with following outline: <br />
              Problem, Solution, Product, Traction (if applicable), Market Size,
              Competition (including successful cases abroad if applicable),
              Business Model, Roadmap, Team, Financials -
              <a
                target="_blank"
                class="font-bold text-blue-600 outline-2"
                href="https://docs.google.com/presentation/d/1zp_tsT6bjRTLigbyqkykltK105ZrtBIM/edit#slide=id.p2"
                >pitch deck example</a
              >
            </p>
            <input
              type="file"
              class="form-control block border-b-2 py-2.5 w-72 md:w-96 h-12 z-20 text-sm text-gray-900 bg-white focus:outline-none focus:border-red-500 focus:ring-red-500"
              id="pitchDeck"
              @change="submitForm('pitchDeck', $event)"
              ref="fileInput"
              required
            />
          </div>

          <div
            class="form-group bg-white w-full p-8 rounded-md text-left space-y-4 border border-gray-200"
          >
            <label for="businessProjection"
              >Please, attach your
              <span class="font-bold"> Business Projection:</span>
              <span class="text-red-500">*</span></label
            >
            <p>
              Attach an Excel file with following information: Historic
              financials (if applicable), Business projections (at least 12
              months)
            </p>
            <input
              type="file"
              class="form-control block border-b-2 py-2.5 w-72 md:w-96 h-12 z-20 text-sm text-gray-900 bg-white focus:outline-none focus:border-red-500 focus:ring-red-500"
              id="businessProjection"
              @change="submitForm('businessProjection', $event)"
              required
            />
          </div>
          <div
            class="form-group bg-white w-full p-8 rounded-md text-left space-y-4 border border-gray-200"
          >
            <label for="applicationVideo"
              >Please, attach your
              <span class="font-bold">
                Application Video (maximum 3 minutes)
              </span>
              <span class="text-red-500">*</span></label
            >
            <p>
              Video with answers to following questions: Problem, Solution,
              Business model, Vision, Team, Why SABAH.lab
            </p>
            <input
              type="file"
              class="form-control block border-b-2 py-2.5 w-72 md:w-96 h-12 z-20 text-sm text-gray-900 bg-white focus:outline-none focus:border-red-500 focus:ring-red-500"
              id="applicationVideo"
              ref="videoFile"
              @change="
                submitForm('applicationVideo', $event);
                validateVideoSize($event);
              "
              required
            />

            <!-- <input
              type="text"
              class="form-control block border-b-2 w-72 md:w-96 h-12 z-20 text-sm text-gray-900 bg-white focus:outline-none focus:border-red-500 focus:ring-red-500"
              id="videoLink"
              v-model="videoLink"
              required
            /> -->
          </div>

          <div class="align-baseline text-right">
            <button
              class="bg-white px-4 rounded-md text-red-500 shadow-md"
              :disabled="
                pitchDeck === null ||
                businessProjection === null ||
                applicationVideo === null
              "
              @click.prevent="
                submitForm;
                this.loading = true;
              "
            >
              <!-- <div
                v-if="this.loading == true"
                role="status flex justify-center "
              >
                <span class="flex justify-center">
                  <svg
                    aria-hidden="true"
                    class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </span>
                <span class="text-4xl flex justify-center py-8"
                  >Loading...</span
                >
                <span class="text-xl flex justify-center py-2"
                  >Please wait it may take a few minutes</span
                >
              </div> -->
              <p class="px-2 py-1.5 font-bold">Submit</p>
            </button>
          </div>
        </form>
      </div>
      <div class="h-screen" v-if="stage === 3">
        <div class="flex flex-col pt-24">
          <p class="text-4xl font-bold text-center">
            Thank you for your application!
          </p>
          <p class="text-center text-2xl">
            We will contact you soon to discuss the next steps.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AWS from "aws-sdk";
import axios from "axios";
import { useToast } from "vue-toastification";
// import JSZip from "jszip";
export default {
  data() {
    return {
      s3: new AWS.S3({
        accessKeyId: "AKIASC3BUFCLZOCINT5P",
        secretAccessKey: "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",
        signatureVersion: "v4",
        region: "eu-central-1",
      }),
      stage: 1,
      teamName: "",
      fullName: "",
      phoneNumber: "",
      pitchDeck: null,
      businessProjection: null,
      applicationVideo: null,
      zipVideo: null,
      videoLink: "",
      openTab: 0,
      applicatonID: "",
      submitTrue: false,
      file: null,
      loading: false,
      closed: true,
    };
  },
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  watch: {
    stage() {
      this.redirectToWebsite();
    },
  },
  mounted() {
    this.redirectToWebsite();
  },
  methods: {
    redirectToWebsite() {
      // wait 5 seconds and redirect to the website
      console.log("stage", this.stage);
      if (this.stage === 3) {
        // move to the top of the page
        window.scrollTo(0, 0);
        console.log("redirecting");
        setTimeout(() => {
          window.location.href = "https://sabahlab.edu.az/";
        }, 10000);
      }
    },
    showErrorToast(message) {
      this.toast.error(message, {
        position: "top-right",
        duration: 3000,
        action: {
          text: "Dismiss",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
    showToast() {
      this.toast.success("Logged in successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
    nextStage() {
      if (
        this.teamName === "" ||
        this.fullName === "" ||
        this.phoneNumber === ""
      ) {
        this.showErrorToast("Please fill in all the fields");
      } else {
        const formData = new FormData();
        formData.append("teamName", this.teamName);
        formData.append("fullName", this.fullName);
        formData.append("phoneNumber", this.phoneNumber);
        axios
          .post(
            "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/tracking/acceleration/apply/",
            formData
          )
          .then((response) => {
            console.log(response);
            // get the application ID
            const applicationID = response.data.id;
            console.log("applicationID", applicationID);
            this.applicationID = applicationID;
            console.log("applicationID", this.applicationID);
            localStorage.setItem("applicationID", applicationID);
            localStorage.setItem("teamName", this.teamName);
            this.teamName = "";
            this.fullName = "";
            this.phoneNumber = "";
            this.stage = 2;
          });
        // this.showToast();
      }
    },
    handleFileSelection(property, event) {
      this[property] = event.target.files[0];
      this.submitForm(property, event);
      if (property === "pitchDeck") {
        this.pitchDeck = event.target.files[0];
      } else if (property === "businessProjection") {
        this.businessProjection = event.target.files[0];
      } else if (property === "applicationVideo") {
        this.applicationVideo = event.target.files[0];
      }
    },
    validateVideoSize(event) {
      const MAX_SIZE = 500 * 1024 * 1024; // 500 MB
      let videoFile = event.target.files[0];
      // if video format is mov convert it to mp4
      if (videoFile.size > MAX_SIZE) {
        this.showErrorToast("Video size should be less than 500MB");
        this.$refs.videoFile.value = "";
      }
    },
    async submitForm(property, event) {
      // const file = this.$refs.fileInput.files[0];
      this[property] = event.target.files[0];
      console.log("this[property]", this[property]);
      console.log("file", event.target.files[0]);
      if (property === "pitchDeck") {
        this.pitchDeck = event.target.files[0];
        console.log("this.pitchDeck", this.pitchDeck);
      } else if (property === "businessProjection") {
        this.businessProjection = event.target.files[0];
      } else if (property === "applicationVideo") {
        this.applicationVideo = event.target.files[0];
      }
      console.log("event", event);
      // const file = event.target.files[0];
      const params = {
        Bucket: "elasticbeanstalk-eu-central-1-143549278359",
        Key:
          "sabahincubation/" +
          localStorage.getItem("teamName") +
          "_" +
          this[property].name,
        Expires: 1000,
        ContentType: this[property].type,
        // ContentEncoding: "base64",
        ACL: "public-read",
        // Region: "eu-central-1",
      };
      const url = await this.getSignedUrl(params);
      if (
        this.pitchDeck !== null ||
        this.businessProjection !== null ||
        (this.applicationVideo !== null && this.submitTrue === true)
      ) {
        console.log("pitch", this[property]);
        this.uploadToS3(url, this[property]);
      }
    },
    getSignedUrl(params) {
      return new Promise((resolve, reject) => {
        this.s3.getSignedUrl("putObject", params, (err, url) => {
          if (err) {
            reject(err);
          }
          resolve(url);
        });
      });
    },

    async uploadToS3(url, file) {
      await axios
        .put(url, file, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // if the button is clicked then show the loading spinner

          console.log(response);
          //if the response is 200 then send the submit request
          if (response.status === 200 && file === this.pitchDeck) {
            console.log("pitch deck uploaded");
            this.pitchDeckUploaded = true;
          } else if (
            response.status === 200 &&
            file === this.businessProjection
          ) {
            console.log("business projection uploaded");
            this.businessProjectionUploaded = true;
          }

          if (
            response.status === 200 &&
            file === this.applicationVideo &&
            this.pitchDeckUploaded === true &&
            this.businessProjectionUploaded === true
          ) {
            console.log("video uploaded");
            console.log("this.loading", this.loading);
            this.sendSubmit();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sendSubmit() {
      this.submitTrue = true;
      this.loading = false;
      console.log("this.loading", this.loading);

      // wait for 2 seconds and then redirect to the home page
      setTimeout(() => {
        this.stage = 3;
      }, 3000);
    },
    // submitForm() {
    //   if (
    //     this.pitchDeck === null ||
    //     this.businessProjection === null ||
    //     this.videoLink === ""
    //   ) {
    //     this.showErrorToast("Please fill in all the fields");
    //   } else {
    //     const formData = new FormData();
    //     console.log("pitchDeck", this.pitchDeck);
    //     console.log("businessProjection", this.businessProjection);
    //     console.log("applicationVideo", this.applicationVideo);
    //     formData.append("pitchDeck", this.pitchDeck);
    //     formData.append("projection", this.businessProjection);
    //     // formData.append("videoLink", this.videoLink);
    //     axios
    //       .post(
    //         "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/tracking/acceleration/apply/update/" +
    //           "http://localhost:3000/",
    //         //  +
    //         //   localStorage.getItem("applicationID") +
    //         //   "/"
    //         formData,
    //         {
    //           headers: {
    //             "Content-Type": "multipart/form-data",
    //           },
    //         }
    //       )
    //       .then((response) => {
    //         console.log(response);
    //         // this.showToast();
    //         this.pitchDeck = null;
    //         this.businessProjection = null;
    //         this.videoLink = "";
    //         this.stage = 3;
    //       });
    //   }
    // },
  },
};
</script>

<style></style>
