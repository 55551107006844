<template>
  <body class="bg-[#003254] mt-12">
    <div class="text-white">
      <div
        class="flex flex-col md:flex-row w-full justify-center md:justify-start px-6 md:space-x-8 md:px-16 lg:px-32"
      >
        <div class="py-12 w-full">
          <div class="flex flex-col items-start justify-start">
            <a href="https://sabahincubation.com/" class="">
              <img
                src="../../public/logoWhite.svg"
                alt=""
                class="w-25 h-20 md:h-20 lg:h-15 xl:h-15"
              />
            </a>

            <div class="py-10 w-full">
              <div class="flex flex-col items-start justify-start text-left">
                <div>
                  <p
                    class="text-sm md:text-base lg:text-md xl:text-md pb-3 hover:text-custom-blue duration-300"
                  >
                    <a
                      target="_blank"
                      href="https://www.google.com/maps/place/SABAH.lab+Acceleration+Center/@40.3768262,49.8590704,18z/data=!3m1!4b1!4m5!3m4!1s0x40307def69a5210d:0xa677620af3c7e1f9!8m2!3d40.3768262!4d49.8599365"
                    >
                      Address: 57 Uzeyir Hajibeyov, Baku, Azerbaijan</a
                    >
                  </p>
                  <p
                    class="text-sm md:text-base lg:text-md xl:text-md pb-3 hover:text-custom-blue"
                  >
                    <a href="mailto:info@sabahlab.edu.az">
                      Email: info@sabahlab.edu.az
                    </a>
                  </p>

                  <p
                    class="text-sm md:text-base lg:text-md xl:text-md pb-3 hover:text-custom-blue"
                  >
                    <a href="tel: +994512771350"> Phone: +994 51 277 13 50 </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="md:py-10 w-full">
          <div class="flex flex-col items-start justify-start">
            <p
              class="text-md md:text-md lg:text-lg xl:text-lg py-2 font-bold text-white"
            >
              Subscribe to our newsletter
            </p>

            <p
              class="text-sm md:text-base lg:text-md xl:text-md pb-5 pt-3.5 text-white text-left"
            >
              Don’t miss out on our latest community news and events
            </p>
            <form action="" @submit.prevent="postNewsletter()">
              <div class="flex justify-start">
                <input
                  type="email"
                  class="w-full text-black md:w-80 h-12 p-3 mr-4 border border-gray-400 rounded-2xl focus:outline-none"
                  placeholder="Enter your email"
                  v-model="email"
                  required
                />
                <button
                  type="submit"
                  class="w-36 lg:w-32 h-12 py-3 bg-custom-blue rounded-2xl"
                >
                  <p class="text-white font-bold text-sm">Subscribe</p>
                </button>
              </div>
            </form>

            <div class="flex flex-row items-start justify-start">
              <div
                class="flex flex-row justify-center align-middle content-center"
              >
                <p
                  class="text-sm md:text-sm lg:text-md xl:text-md py-7 text-white text-left"
                >
                  Follow us on social media:
                </p>

                <div
                  class="flex flex-row py-4 px-4 space-x-2 md:space-x-0 justify-start items-start"
                >
                  <a
                    href="https://www.linkedin.com/company/sabahlab"
                    target="_blank"
                    class="flex justify-start items-start"
                  >
                    <img
                      src="../assets/linkedin.svg"
                      alt=""
                      class="md:h-10 md:w-3/4 xl:w-3/4 lg:w-3/4 w-full"
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/sabah.lab/"
                    class="flex justify-start items-start"
                  >
                    <img
                      src="../assets/instagram.svg"
                      alt=""
                      class="md:h-10 md:w-3/4 xl:w-3/4 lg:w-3/4 w-full"
                  /></a>

                  <a
                    href="https://www.facebook.com/sabah.lab421"
                    target="_blank"
                    class="flex justify-start items-start"
                  >
                    <img
                      src="../assets/facebook.svg"
                      alt=""
                      class="md:h-10 md:w-3/4 xl:w-3/4 lg:w-3/4 w-full"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCs9yqqpSQFau3wBjX1XvtRQ"
                    target="_blank"
                    class="flex justify-start items-start"
                  >
                    <img
                      src="../assets/youtube.svg"
                      alt=""
                      class="md:h-10 md:w-3/4 xl:w-3/4 lg:w-3/4 w-full"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-gray-800">
        <div
          class="md:w-auto xl:w-auto mx-auto flex flex-col xl:items-stretch justify-between xl:flex-row col-end-1"
        >
          <div class="flex flex-row justify-start align-middle items-center">
            <p class="text-sm md:text-base lg:text-md xl:text-md px-5 py-2">
              © 2022 SABAH.incubation All rights reserved.
            </p>
          </div>
          <div class="p-3 flex justify-end px-5"></div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      email: "",
    };
  },

  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },

  methods: {
    showToast() {
      this.toast.success("Email saved successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },

    showErrorToast() {
      this.toast.error("Please enter a valid email address!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },

    //post newsletter
    postNewsletter() {
      axios
        .post(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/postNewsletter/",
          {
            email: this.email,
          }
        )
        .then((response) => {
          console.log(response);
          this.showToast();
          this.email = "";
        })
        .catch((error) => {
          console.log(error);
          this.showErrorToast();
        });
    },
  },
};
</script>

<style></style>
