<template>
  <body
    class="bg-bg-blue px-6 lg:px-20 divide-y-2 divide-zinc-300 justify-center overflow-x-hidden"
  >
    <span></span>
    <div class="">
      <div class="p-0 max-w-4xl">
        <p class="text-2xl text-left font-bold md:mt-10 my-3">
          SABAH.incubation
        </p>

        <p class="text-left py-4">
          We aim to establish a competitive entrepreneurial ecosystem to drive
          Azerbaijan’s economic diversification and secure its innovation hub
          status in the region.
        </p>
        <p class="text-left py-4">
          Creating and developing early-stage companies in various sectors of
          the economy through unique learning programs and a sustainable
          ecosystem of young entrepreneurs and industry professionals.
        </p>
        <p class="text-left py-4">
          A section of this content is included in our core curriculum for
          startup teams as a part of SABAH.incubation
        </p>
      </div>

      <div class="flex flex-col lg:flex-row pt-12">
        <div class="w-auto lg:pr-12">
          <articleCategories />
        </div>

        <div class="flex flex-col">
          <div class="pt-8 pb-2 lg:pt-0 lg:pb-8">
            <div
              class="w-full xl:w-full py-4 border-2 border-zinc-300 rounded-xl"
            >
              <SearchBar />
            </div>
          </div>
          <div
            class="border-2 rounded-xl border-zinc-300 flex flex-col justify-center items-center"
          >
            <div class="w-11/12 flex flex-col justify-center items-center py-6">
              <div class="space-y-12">
                <div
                  v-for="post in posts"
                  :key="post.id"
                  @click="postDetails(post.id)"
                  class="border-2 border-zinc-300 rounded-2xl w-full px-4 py-4 hover:shadow-lg duration-300 flex flex-col justify-start items-start"
                >
                  <div>
                    <h1 class="text-lg font-bold flex justify-start">
                      {{ post.title }}
                    </h1>
                    <!-- <p class="text-xl flex justify-start">Nihat Muradzade</p> -->
                  </div>
                  <div class="">
                    <ul
                      v-for="tag in post.tags"
                      :key="tag.id"
                      class="inline-flex py-2 text-sm sm:text-sm md:text-base lg:text-md xl:text-md"
                    >
                      <li
                        class="bg-tag-blue cursor-pointer text-xs px-2 rounded w-max sm:text-sm md:text-base lg:text-md xl:text-md hover:bg-custom-blue hover:text-white duration-300"
                      >
                        {{ tag }}
                      </li>
                      <span class="px-2"></span>
                    </ul>
                  </div>
                  <p class="flex justify-start text-left text-sm">
                    {{ post.subtitle }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import axios from "axios";
import SearchBar from "../components/SearchBar.vue";
import articleCategories from "../components/articleCategories.vue";

export default {
  data() {
    return {
      title: "",
      posts: this.posts,
    };
  },
  components: {
    articleCategories,
    SearchBar,
  },
  mounted() {
    if (window.location.href.includes("subcategories")) {
      this.getSubCatData();
    }
  },
  methods: {
    async postDetails(postId) {
      axios
        .get(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/post/" +
            postId +
            "/"
        )
        .then((response) => {
          this.posts = response.data;
          console.log(this.posts);
        });
      // if the post category is book, go to book details page
      //get the selected post category with a for loop
      for (let i = 0; i < this.posts.length; i++) {
        console.log(this.posts[i].category);
        if (this.posts[i].category == "Books") {
          this.$router.push("/bookDetails/" + postId);
        } else if (this.posts[i].category == "Articles") {
          this.$router.push("/postdetails/" + postId);
        } else if (this.posts[i].category == "Videos") {
          this.$router.push("/videodetails/" + postId);
        }
      }
      //this.router.oush

      // if (this.posts.category == "book") {
      //   this.$router.push("/bookDetails/ " + postId);
      // }
      // // if the post category is article, go to article details page
      // if (this.posts.category == "article") {
      //   this.$router.push("/postdetails/" + postId);
      // }
    },

    async getSubCatData() {
      let url = window.location.href;
      console.log(url);

      let counter = 0;
      for (let i = 0; i < url.length; i++) {
        if (url[i] == "/") {
          counter++;
        }

        if (counter == 4) {
          url = url.slice(i + 1);
          for (let j = 0; j < url.length; j++) {
            if (url[j] === "%" || url[j + 1] === "2" || url[j + 1] === "0") {
              console.log("test");
              url = url.slice(0, j) + " " + url.slice(j + 3);
            }
          }
          break;
        }
      }
      console.log(url);
      if (url[url.length - 1] === "/") {
        url = url.slice(0, url.length - 1);
      }

      console.log(url);
      await axios
        .get(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/post/" +
            url
        )
        .then((response) => {
          console.log(response.data);
          // this.mentorDatas = response.data.query;
          // console.log(this.mentorDatas);
          this.isLoading = false;
          console.log(response.data);
          this.posts = response.data;
          this.posts.forEach((post) => {
            post.tags = post.tags.split(", ");
          });
        })

        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
