<template>
  <div class="bg-zinc-100 border-2 border-zinc-300 rounded-xl">
    <div>
      <p class="text-left text-xl font-bold px-4 py-6">Have a suggestion?</p>
      <p class="text-left px-4">
        If you have a suggestion for a video, essay, or other resource we should
        add to the SABAH.incubation,
        <a
          class="underline"
          target="_blank"
          href="https://forms.gle/yUz4sayccnFTkynr7"
        >
          submit it here.
        </a>
      </p>

      <p class="text-left text-lg font-bold px-4 pt-6">Related articles</p>
      <p class="text-left p-4">
        Here are more articles you might find interesting.
      </p>
    </div>
    <div class="space-y-8 px-4 pb-6">
      <div
        v-for="post in posts"
        :key="post.id"
        @click="getPostData(post.id)"
        class="cursor-pointer border-2 border-zinc-300 rounded-2xl w-full px-4 py-4 hover:bg-custom-blue/5 duration-300 flex flex-col justify-start items-start"
      >
        <div>
          <p class="text-lg font-bold flex justify-start text-left">
            {{ post.title }}
          </p>
        </div>
        <div
          class="inline-flex py-2 text-sm sm:text-sm md:text-base lg:text-md xl:text-md text-left"
        >
          <tagsNew :searchTags="post.tags" />
        </div>
        <p class="flex justify-start text-left text-sm">
          {{ post.subtitle }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import tagsNew from "../components/tagsNew.vue";

export default {
  data() {
    return {
      posts: [],
    };
  },

  //   mounted() {

  //   },
  // mounted() {
  //   this.getPosts();
  // },

  beforeMount() {
    this.getPosts();
  },
  components: {
    tagsNew,
  },
  watch: {
    $route(to, from) {
      console.log("route changed");
      console.log(to);
      console.log(from);
      if (to.params.id !== from.params.id) {
        // this.getPostData(to.params.id);
        console.log("route changed");
        console.log(this.$route.params.id);
        this.getPostData(this.$route.params.id);
        this.$router.go(to.fullPath).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err;
          }
        });

        console.log("sadDas");
      }
    },
  },
  methods: {
    getPosts() {
      axios
        .get(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/all_posts/"
        )
        .then((response) => {
          this.posts = response.data;

          //randomize the posts and select only 5 of them
          this.posts = this.posts.sort(() => Math.random() - 0.5).slice(0, 5);

          //select 5 posts with the same tag as the current post
          // this.posts = this.posts.filter((post) => {
          //   return post.tags.includes(this.post.tags[0]);
          // });

          for (let i = 0; i < this.posts.length; i++) {
            this.posts[i].tags = this.posts[i].tags.split(",");
            this.newTags = this.posts[i].tags;
          }
          console.log(this.newTags);
        });
    },
    // async postDetails(postId) {
    //   console.log(postId);

    //   axios
    //     .get(
    //       "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/post/" +
    //         postId +
    //         "/"
    //     )
    //     .then((response) => {
    //       // window.location.href = "/postdetails/" + postId;
    //       this.posts = response.data;
    //     });
    //         for (let i = 0; i < this.posts.length; i++) {
    //     console.log(this.posts[i].category);
    //     if (this.posts[i].category == "Books") {
    //       this.$router.push("/bookDetails/" + postId);
    //     } else if (this.posts[i].category == "Articles") {
    //       this.$router.push("/postDetails/" + postId);
    //     }
    //   }

    //   // this.$router.push("/postdetails/" + postId);
    // },

    goToLink(link) {
      window.open(this.post.source, "_blank");
      window.open(link, "_blank");
    },

    async getPostData(postId) {
      // this.postId = this.$route.params.id;

      axios
        .get(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/post/" +
            postId
        )
        .then((response) => {
          this.post = response.data;
          this.post.tags = this.post.tags.split(",");
          console.log(this.post.category);
          console.log(postId);

          // for (let i = 0; i < this.posts.length; i++) {
          //   console.log(this.posts[i].category);
          //   if (this.posts[i].category == "Books") {
          //     this.$router.push("/bookDetails/" + postId);
          //   } else if (this.posts[i].category == "Articles") {
          //     this.$router.push("/postDetails/" + postId);
          //   }
          // }

          if (this.post.category == "Books") {
            const path = `/bookDetails/${postId}`;
            if (this.$route.path !== path) this.$router.push(path);
            // this.$router.push("/bookDetails/" + postId, () => {});
          } else if (this.post.category == "Articles") {
            const path = `/postDetails/${postId}`;
            console.log(path);
            if (this.$route.path !== path) this.$router.push(path);
            // this.$router.push("/postDetails/" + postId, () => {});
          }
        });
    },
  },
};
</script>

<style></style>
