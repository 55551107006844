<template>
  <div
    class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full"
    role="dialog"
    aria-modal="true"
    aria-labelledby="modal-headline"
  >
    <div v-for="members in teamDetailsData" :key="members">
      <div v-for="member in members" :key="member">
        <p>{{ member.fullName }}</p>
      </div>
    </div>

    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div class="sm:flex sm:items-start">
        <div class="mt-3 text-center sm:mt-0 w-full sm:text-left">
          <div class="flex justify-between">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              Details of {{ teamDetailsData.teamName }} Long
            </h3>
            <button @click="$emit('close')">❌</button>
          </div>

          <div class="mt-2">
            <p class="text-sm text-gray-500">
              See details and edit the team's information
            </p>
            <br />

            <div class="flex flex-col pb-2">
              <div class="table-auto justify-between">
                <div class="text-left">
                  <table class="table-auto w-full justify-between divide-y-2">
                    <thead>
                      <tr class="text-left">
                        <th>Team members</th>
                        <th>Position</th>
                        <th>Email Address</th>
                        <th>Phone numbers</th>
                        <th>CV</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody v-for="members in memberData" :key="members">
                      <tr>
                        <td class="text-left">
                          <input
                            type="text"
                            class="w-48"
                            placeholder="Fullname"
                            v-model="members.fullName"
                            @input="
                              handleInput($event, members.memberID, 'fullname')
                            "
                          />
                        </td>
                        <td class="text-left">
                          <input
                            type="text"
                            class="w-48"
                            placeholder="Position"
                            v-model="members.position"
                            @input="
                              handleInput($event, members.memberID, 'fullname')
                            "
                            @click="
                              getID(
                                members.memberID,
                                'position',
                                members.position
                              )
                            "
                          />
                        </td>
                        <td class="text-left">
                          <input
                            type="text"
                            class="w-48"
                            placeholder="Email address"
                            v-model="members.email"
                            @click="
                              getID(members.memberID, 'email', members.fullName)
                            "
                          />
                        </td>
                        <td class="text-left">
                          <input
                            type="text"
                            class="w-48"
                            placeholder="Phone number"
                            v-model="members.phoneNumber"
                          />
                        </td>

                        <td
                          class="text-left text-custom-blue underline underline-offset-2"
                        >
                          nihat_cv
                        </td>
                        <td>
                          <button
                            class="bg-red-500 hover:bg-red-700 text-white font-bold rounded px-2"
                            @click="deleteMember(members.memberID)"
                          >
                            -
                          </button>
                        </td>
                      </tr>
                    </tbody>

                    <tbody>
                      <tr>
                        <td class="text-left">
                          <input
                            type="text"
                            v-model="newUserFullName"
                            placeholder="Fullname"
                          />
                        </td>
                        <td class="text-left">
                          <input
                            type="text"
                            v-model="newUserPosition"
                            placeholder="Position"
                          />
                        </td>
                        <td class="text-left">
                          <input
                            type="text"
                            v-model="newUserEmail"
                            placeholder="Email"
                          />
                        </td>
                        <td class="text-left">
                          <input
                            type="text"
                            v-model="newUserNumber"
                            placeholder="Phone number"
                          />
                        </td>

                        <td
                          class="text-left text-custom-blue underline underline-offset-2"
                        >
                          <input type="text" placeholder="CV link" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="flex justify-between text-right border-t-2 pt-8">
                    <p class="font-bold">Files</p>
                    <div
                      class="flex flex-col text-custom-blue underline underline-offset-2"
                    >
                      <button><p class="">Files of the team</p></button>
                    </div>
                  </div>

                  <div class="flex justify-between border-y-2">
                    <p class="font-bold">Status:</p>
                    <div
                      class="rounded-full text-white text-left font-bold"
                      :class="changeStatusColor(teamDetailsData.status)"
                    >
                      {{ teamDetailsData.status }}
                    </div>
                  </div>

                  <div class="flex justify-between text-right border-b-2">
                    <p class="font-bold">Last Meeting:</p>
                    <div class="flex flex-col">
                      <p class="">{{ lastMeeting }}</p>
                    </div>
                  </div>
                  <div class="flex justify-between text-left border-b-2">
                    <p class="font-bold">Online library credentials</p>
                    <div class="flex flex-col">
                      <p class="">Created</p>
                    </div>
                  </div>
                  <div class="flex justify-between text-right border-b-2">
                    <p class="font-bold">Give feedback:</p>
                    <div class="flex flex-col py-2">
                      <textarea
                        type="text"
                        class="w-48 border-2 rounded-2xl px-1.5 py-1.5"
                        placeholder="Short feedback"
                        v-model="shortFeedback"
                      />
                    </div>
                  </div>
                  <div
                    @click="this.showFeedbacks = !this.showFeedbacks"
                    class="cursor-pointer flex justify-between text-right border-b-2"
                  >
                    <p class="font-bold">Team Feedback:</p>
                    <div class="flex flex-col">
                      <p class="">Click to see weekly feedbacks</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="this.showFeedbacks" class="overflow-auto">
              <table class="w-full table-auto table-striped">
                <thead class="overflow-hidden">
                  <tr
                    class="text-left flex space-x-4 border-b-2 p-2 leading-4 text-gray-500 uppercase tracking-wider"
                  >
                    <th class="w-32">Week 1</th>
                    <th class="w-32">Week 2</th>
                    <th class="w-32">Week 3</th>
                    <th class="w-32">Week 4</th>
                    <th class="w-32">Week 5</th>
                    <th class="w-32">Week 6</th>
                    <th class="w-32">Week 7</th>
                    <th class="w-32">Week 8</th>
                    <th class="w-32">Week 9</th>
                    <th class="w-32">Week 10</th>
                    <th class="w-32">Week 11</th>
                    <th class="w-32">Week 12</th>
                  </tr>
                </thead>

                <tbody class="overflow-x-hidden">
                  <tr class="border-b-2 divide-x-2 flex">
                    <td contenteditable class="text-left p-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Optio quae minima velit blanditiis dolor unde?
                    </td>

                    <td contenteditable class="text-left p-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Optio quae minima velit blanditiis dolor unde?
                    </td>
                    <td contenteditable class="text-left p-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Optio quae minima velit blanditiis dolor unde?
                    </td>
                    <td contenteditable class="text-left p-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Optio quae minima velit blanditiis dolor unde?
                    </td>
                    <td contenteditable class="text-left p-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Optio quae minima velit blanditiis dolor unde?
                    </td>
                    <td contenteditable class="text-left p-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Optio quae minima velit blanditiis dolor unde?
                    </td>
                    <td contenteditable class="text-left p-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Optio quae minima velit blanditiis dolor unde?
                    </td>
                    <td contenteditable class="text-left p-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Optio quae minima velit blanditiis dolor unde?
                    </td>
                    <td contenteditable class="text-left p-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Optio quae minima velit blanditiis dolor unde?
                    </td>
                    <td contenteditable class="text-left p-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Optio quae minima velit blanditiis dolor unde?
                    </td>
                    <td contenteditable class="text-left p-2">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Optio quae minima velit blanditiis dolor unde?
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="flex flex-col py-8">
              <label for="email" class="text-sm font-bold">Team Status</label>
              <div class="flex space-x-4">
                <button>
                  <p
                    class="focus:border-2 focus:border-custom-blue"
                    value="red"
                  >
                    🔴
                  </p>
                </button>
                <button>
                  <p
                    class="focus:border-2 focus:border-custom-blue"
                    value="red"
                  >
                    🟡
                  </p>
                </button>
                <button>
                  <p
                    class="focus:border-2 focus:border-custom-blue"
                    value="green"
                  >
                    🟢
                  </p>
                </button>
              </div>
            </div>
          </div>
          <div class="pt-8 flex justify-between">
            <div>
              <button
                @click="saveChanges()"
                class="px-4 py-2 hover:shadow-xl hover:font-bold duration-300 rounded-2xl bg-custom-blue text-white"
              >
                Save
              </button>
            </div>
            <div>
              <button
                @click="deleteTeam()"
                class="bg-red-500 text-white px-4 py-2 hover:shadow-xl hover:font-bold duration-300 rounded-2xl"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- editUserModal -->
</template>

<script>
import axios from "axios";
export default {
  props: ["teamID"],
  data() {
    return {
      posts: [],
      maxChar: 100,
      minChar: 1,
      counter: 0,
      editUserModal: false,
      date: "",
      dropdown: false,
      selectedMail: "",
      selectedTeamStatus: "",
      selectedStatus: "",
      value: "",
      rowData: [],
      shortFeedback: "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
      showFeedbacks: false,
      teamName: "",
      search: "",
      fullName: "",
      position: "",
      memberName: "",
      statusDropdown: false,
      statusText: false,
      teamIndividualDropdown: false,
      teamIndividualText: false,
      teamFormationDropdown: false,
      teamFormationText: false,
      interviewStageDropdown: false,
      interviewStageText: false,
      cvStageDropdown: false,
      cvStageText: false,
      lastMeetingDropdown: false,
      lastMeetingText: false,
      meetingDiscord: "",
      meetingEmail: "",
      selectedCategoryStatus: "All",
      selectedCategoryCV: "All",
      selectedCategoryInterviewStage: "All",
      teamDatas: [],
      selectedRows: [],
      teamDetailsData: "",
      cvStageTextNew: "",
      name: "",
      changesArr: [],
      CVstatus: "",
      memberID: "",
      newUserFullName: "",
      newUserEmail: "",
      newUserPosition: "",
      newUserNumber: "",
    };
  },

  computed: {
    filterData: function () {
      var vm = this;
      var status = vm.selectedCategoryStatus;
      var cv = vm.selectedCategoryCV;
      if (status === "All" && cv === "All") {
        return vm.teamDatas;
      } else if (status === "All") {
        return vm.teamDatas.filter(function (teamDatas) {
          return teamDatas.CVstatus === cv;
        });
      } else if (cv === "All") {
        return vm.teamDatas.filter(function (teamDatas) {
          return teamDatas.status === status;
        });
      } else {
        return vm.teamDatas.filter(function (teamDatas) {
          return teamDatas.status === status && teamDatas.CVstatus === cv;
        });
      }
    },
  },

  mounted() {
    this.teamDetails();
    // this.currentDate();
  },
  methods: {
    getID(id, column, value) {
      console.log(value);
      console.log(id);
      this.memberID = id;
      console.log(column);
      // set timeout for 1 second

      setTimeout(() => {
        this.saveMemberChanges(value);
      }, 1000);
    },

    handleInput(e, memberID, value) {
      console.log(e.target.innerText);
      console.log(memberID);
      console.log(value);
      // console.log(e.target.innerHTML);
      for (let i = 0; i < this.teamDatas.length; i++) {
        if (this.teamDatas[i].memberID === memberID) {
          this.teamData = this.teamDatas[i];
          console.log(this.teamData);
        }
      }

      if (value === "fullname") {
        this.fullname = this.memberData.fullname;

        console.log(this.fullname);
      }
      if (value === "lastMeeting") {
        this.lastMeeting = this.teamData.lastMeeting;
        console.log(this.lastMeeting);
      }

      if (value === "MVPstage") {
        this.interviewStage = this.teamData.interviewStage;
        console.log(this.interviewStage);
      }

      if (value === "teamFormation") {
        this.teamFormation = this.teamData.teamFormation;
        console.log(this.teamFormation);
      }

      setTimeout(() => {
        console.log("saved");
        this.saveChanges();
      }, 2000);
    },

    goToStats() {
      this.$router.push("/seeStats");
    },

    removeMember() {
      console.log(this.memberID);
      axios
        .put(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/tracking/remove/member/" +
            this.memberID +
            "/" +
            this.teamID +
            "/"
        )
        .then(() => {
          this.teamDetails();
        });
    },
    deleteMember(memberID) {
      console.log(this.memberID);
      console.log(memberID);
      axios
        .delete(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/tracking/member/delete/" +
            memberID +
            "/"
        )
        .then(() => {
          this.teamDetails();
        });
    },

    // saveMemberChanges() {
    //   console.log(this.memberID);
    //   console.log(this.memberData);
    //   console.log();
    //   console.log(this.memberPosition);

    //   // formData all the data that will be sent to the backend

    //   axios.put(
    //     "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/tracking/member/update/" +
    //       this.memberID +
    //       "/",
    //     {
    //       fullName: this.fullName,
    //       position: this.position,
    //     }
    //   );
    // },

    sendEmails(teamID) {
      let rows = this.selectedRows;
      console.log(rows);
      console.log(teamID);

      let emails = this.selectedMail;

      console.log(emails);
      axios
        .post(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/tracking/bulk-email/" +
            emails +
            "/",
          {
            teams_list: ["cf97b15f-80ea-41e4-aae7-d6ded4d7f744"],
          }
        )
        .then((response) => {
          this.emailResp = response.data;
        });
      console.log("Emails sent");
    },

    async teamDetails() {
      // this.postId = this.$route.params.id;
      console.log(this.teamID);

      axios
        .get(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/tracking/team/" +
            this.teamID +
            "/"
        )
        .then((response) => {
          this.teamDetailsData = response.data.team;

          if (this.teamDetailsData.lastMeeting == null) {
            console.log("empty");
            this.teamDetailsData.lastMeeting = "NOT SET";
          }

          this.memberData = response.data.members;
          console.log(this.memberData.memberID);
          console.log(this.teamDetailsData);
          console.log(this.teamDetailsData.teamName);
          console.log(this.memberData);
          for (let i = 0; i < this.memberData.length; i++) {
            this.memberID = this.memberData[i].memberID;
            console.log(this.memberID);
          }
          for (let i = 0; i < this.memberData.length; i++) {
            this.memberName = this.memberData[i].fullName;
            console.log(this.memberName);
          }
          for (let i = 0; i < this.memberData.length; i++) {
            this.memberPosition = this.memberData[i].position;
            console.log(this.memberPosition);
          }

          this.fullName = this.memberData.fullName;
          console.log(this.fullName);
          this.position = this.memberData.position;
          console.log(this.position);

          // // get the member id
          // for (let i = 0; i < this.memberData.length; i++) {
          //   this.memberID = this.memberData[i].memberID;
          //   console.log(this.memberID);
          // }
          // console.log(this.memberID);

          // // teamFeedback array
          // for (let i = 0; i < this.teamDetailsData.teamFeedback.length; i++) {
          //   this.teamFeedback = this.teamDetailsData.teamFeedback[i];
          //   console.log(this.teamFeedback);
          // }

          this.dropdown = !this.dropdown;
        });
    },

    changeStatusColor(status) {
      if (status == "Active") {
        return "text-green-500";
      } else if (status == "Yellow") {
        return "text-yellow-500";
      } else if (status == "Passive") {
        return "text-red-500";
      }
    },
    deleteTeam() {
      // delete account
      alert("Are you sure you want to delete this team?");
    },

    saveChanges() {
      // get the edited member data and send it to the backend

      // if new member is added then call addNewMember function
      if (
        this.newUserFullName != "" &&
        this.newUserPosition != "" &&
        this.newUserEmail != "" &&
        this.newUserPhone != ""
      ) {
        this.addNewMember();
      }

      console.log(this.teamID);
      console.log(this.teamDetailsData.teamName);
      console.log(this.changesArr);
      axios.put(
        "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/tracking/update_all_details/" +
          this.teamID +
          "/",
        // formData
        {
          team_update: {
            teamName: this.teamDetailsData.teamName,
            teamID: this.teamID,
            status: this.status,
            teamIndividual: this.teamIndividual,
            teamFormationStatus: this.teamDetailsData.teamFormationStatus,
            interviewStatus: this.teamDetailsData.interviewStatus,
            CVstatus: this.teamDetailsData.CVstatus,
            lastMeeting: this.teamDetailsData.lastMeeting
              .split("/")
              .reverse()
              .join("-"),
          },
          new_member: {
            memberID: 1,
            email: this.memberEmail,
            position: this.memberPosition,
            fullName: this.memberName,
            phoneNumber: this.memberPhone,
          },
        }
      );

      console.log("SAVED");
    },
    addNewMember() {
      console.log(this.newUserEmail);
      console.log(this.newUserFullName);
      console.log(this.newUserPosition);
      axios.put(
        "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/tracking/new_member/create/" +
          this.teamID +
          "/",

        {
          memberID: "1",
          email: this.newUserEmail,
          position: this.newUserPosition,
          fullName: this.newUserFullName,
          phoneNumber: this.newUserPhone,
        }
      );

      console.log("SAVED");
    },

    // },

    sendStatusEmail(selectedColor) {
      // send emails to selected users
      if (selectedColor == "red") {
        alert(
          "Are you sure you want to send emails to selected users with status: Red?"
        );
        this.selectedStatus = "red";
      } else if (selectedColor == "yellow") {
        alert(
          "Are you sure you want to send emails to selected users with status: Yellow?"
        );
      } else if (selectedColor == "green") {
        alert(
          "Are you sure you want to send emails to selected users with status: Green?"
        );
      }
    },

    editUser() {
      //show the edit user modal
      this.editUserModal = true;
    },
    tableColor() {
      return this.counter % 2 !== 0 ? `grey` : "white";
    },
  },
};
</script>

<style></style>
