// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAx8LEi48-87qX2Nf_LL5tviQreFv2tX4o",

  authDomain: "sabah-1bb05.firebaseapp.com",

  projectId: "sabah-1bb05",

  storageBucket: "sabah-1bb05.appspot.com",

  messagingSenderId: "432271434857",

  appId: "1:432271434857:web:5a0322cbf3ac168b072ef8",

  measurementId: "G-BKLMMQTQMV",
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

export default firebase;
