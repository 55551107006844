<template>
  <div class="w-full lg:w-56 xl:w-64">
    <div class="">
      <div
        class="overflow-y-auto overflow-x-hidden py-4 rounded-xl border-2 border-zinc-300"
      >
        <aside class="w-full lg:w-64" aria-label="Sidebar">
          <div class="overflow-y-auto py-4 px-3 rounded">
            <ul v-for="category in categories" :key="category.id">
              <li>
                <button
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click="makeDropdown(category.id)"
                  type="button"
                  class="hover:bg-gray-200 flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group"
                >
                  <!-- @click="to_categories(category.name)" -->
                  <span
                    class="flex-1 ml-3 text-left whitespace-nowrap text-black"
                    sidebar-toggle-item
                  >
                    {{ category.name }}</span
                  >
                  <svg
                    sidebar-toggle-item
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>

                <ul
                  v-if="category.id == this.dropdown"
                  class="flex flex-col"
                  aria-label="submenu"
                >
                  <li
                    v-for="subcategory in category.subcategories"
                    :key="subcategory.id"
                  >
                    <a
                      @click="
                        to_subcategories(
                          category.name,
                          subcategory.name.substring(
                            0,
                            subcategory.name.indexOf('|')
                          )
                        )
                      "
                      v-if="subcategory.name != 'Coming soon...'"
                      href="#"
                      class="hover:bg-gray-200 flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group"
                    >
                      {{
                        subcategory.name.substring(
                          0,
                          subcategory.name.indexOf("|")
                        )
                      }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </div>
  </div>

  <!-- 
    <div class="flex justify-start pt-4 lg:pt-8">
              <TagsNew :searchTags="this.searchTags" />
            </div> -->
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      categories: this.categories,
      subcategories: this.subcategories,
      showDropdown: false,
      showDropdownArticles: false,
      showDropdownBooks: false,
      dropdown: null,
      articles: [],
      disabled: false,
    };
  },

  mounted() {
    this.makeDropdown();
  },
  beforeMount() {
    this.getCategories();
  },
  methods: {
    makeDropdown(id) {
      console.log(this.showDropdown);
      if (this.dropdown == id) {
        this.dropdown = null;
      } else {
        this.dropdown = id;
      }
    },

    to_categories(str) {
      this.$router.push("/categories/" + str);
      window.location.href = "/categories/" + str;
    },
    to_subcategories(str, str2) {
      this.$router.push("/subcategories/" + str + "/" + str2);
      window.location.href = "/subcategories/" + str + "/" + str2;
    },
    getCategories() {
      axios
        .get(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/all_categories/"
        )
        .then((response) => {
          this.categories = response.data;
          //if categort is "Coming soon..." then make it disabled
          this.categories.forEach((category) => {
            if (category.name == "Coming soon...") {
              category.disabled = true;
            }
          });
          console.log(response.data);
        });
    },
  },
};
</script>

<style></style>
