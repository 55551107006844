<template>
  <div
    class="md:w-auto xl:w-auto mx-auto flex items-stretch justify-between flex-row col-end-1"
  >
    <div class="w-full px-4 items-start">
      <form @submit.prevent="searchPost">
        <div class="flex items-stretch justify-center h-full">
          <div class="relative w-full">
            <input
              type="search"
              placeholder="Search..."
              ref="search"
              :maxlength="maxChar"
              :minlength="minChar"
              v-model="search"
              id='location-search"'
              class="hover:shadow-xl duration-300 block p-2.5 px-8 w-full h-12 z-20 text-sm text-gray-900 border-2 border-zinc-300 rounded-lg"
              required=""
            />

            <button
              type="submit"
              class="absolute top-0 right-0 py-2.5 px-4 h-12 text-sm font-medium rounded-r-lg text-gray-400 duration-300 focus:ring-4 focus:outline-none focus:ring-gray-300"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBar",

  data() {
    return {
      maxChar: 50,
      getMentorDetails: false,
      mentorDatas: [],
      search: "",
    };
  },

  methods: {
    searchPost() {
      console.log(this.search);
      location.assign(`/search/${this.search}/`);
    },
  },
};
</script>

<style></style>
