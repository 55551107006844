<template>
  <body class="bg-bg-blue">
    <div class="px-6 lg:px-20 bg-bg-blue">
      <nav class="py-3 mx-auto md:flex md:justify-between md:items-center">
        <div class="flex items-center justify-between py-2">
          <router-link to="/" class="text-blue-600 font-bold">
            <router-link to="/" class="text-blue-600 font-bold">
              <img
                class="relative w-auto h-8 md:h-12 md:w-auto"
                src="../../public/Logo.png"
            /></router-link>
          </router-link>
          <!-- Mobile menu button -->
          <div @click="showMenu = !showMenu" class="flex md:hidden">
            <button
              type="button"
              class="text-gray-800 hover:text-gray-400 focus:outline-none focus:text-gray-400"
            >
              <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                <path
                  fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
        <ul
          :class="showMenu ? 'flex' : 'hidden'"
          class="flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-4 lg:space-x-8 md:mt-0"
        >
          <li class="nav-item text-left">
            <a class="nav-link" href="#"
              ><router-link
                to="/"
                @click="showMenu = !showMenu"
                class="text-gray-800 hover:text-custom-blue md:text-md truncate"
                >Home</router-link
              >
            </a>
          </li>

          <li class="nav-item text-left">
            <a
              class="nav-link text-gray-800 hover:text-custom-blue md:text-md truncate"
              href="https://www.sabahlab.edu.az/program"
              target="_blank"
              @click="showMenu = !showMenu"
              >Take a look
            </a>
          </li>

          <li class="nav-item text-left" v-if="isLoggedIn == false">
            <a class="nav-link" href="#"
              ><router-link
                to="/login"
                @click="showMenu = !showMenu"
                class="text-white hover:text-white hover:bg-indigo-500 hover:border-indigo-500 duration-300 md:text-md bg-custom-blue border-2 border-custom-blue px-4 py-1 rounded-lg"
                >Login</router-link
              >
            </a>
          </li>

          <li class="nav-item text-left" v-if="isLoggedIn">
            <a class="nav-link" href="#"
              ><router-link
                v-if="isLoggedIn"
                @click="handleSignOut"
                to="/"
                class="text-white hover:text-white hover:bg-indigo-500 hover:border-indigo-500 duration-300 md:text-md bg-custom-blue border-2 border-custom-blue px-4 py-1 rounded-lg"
                >Logout</router-link
              >
            </a>
          </li>
        </ul>
      </nav>
    </div>

    <!-- <FooterComp /> -->
  </body>
</template>
<script>
// import FooterComp from "../components/FooterComp.vue";
import firebase from "../utilities/firebase";

export default {
  props: { isLoggedIn: Boolean },

  data() {
    return {
      showMenu: false,
    };
  },

  components: {
    // FooterComp,
  },

  methods: {
    handleSignOut() {
      if (this.isLoggedIn) {
        firebase
          .auth()
          .signOut()
          .then(() => {
            localStorage.removeItem("userEmail");
            localStorage.removeItem("userFullname");

            this.$router.push("/");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
