<template>
  <div class="min-h-screen">
    <h1 class="flex justify-center md:text-3xl p-4 font-bold">
      Search results
    </h1>
    <div class="pb-7 px-6 md:px-0 px-23 mx-24">
      <TSSearchBar />
    </div>
    <!-- <div
      class="w-full flex flex-col justify-center py-12"
      v-if="notFound == true"
    >
      <p class="text-4xl text-center bt-medium -pb-12">
        Oops, nothing found 📡
      </p>
    </div> -->

    <!-- table for members -->
    <table v-if="this.isMember == true" class="table-auto justify-between">
      <thead class="w-screen">
        <tr class="text-left justify-evenly border-b-2 bg-gray-100">
          <th
            class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100"
          >
            <label
              class="text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"
            >
              <input
                type="checkbox"
                class="form-checkbox focus:outline-none focus:shadow-outline"
                @click="selectAllCheckboxes($event)"
              />
            </label>
          </th>
          <th class="w-72">Fullname</th>
          <th class="w-72" @click="statusDropdown = !statusDropdown">
            <p class="">Position</p>
          </th>

          <th @click="lastMeetingDropdown = !lastMeetingDropdown" class="w-72">
            <p class="">Email</p>
          </th>
          <th @click="cvStageDropdown = !cvStageDropdown" class="w-72">
            <p class="">Phone number</p>
          </th>
          <th
            @click="interviewStageDropdown = !interviewStageDropdown"
            class="w-72"
          >
            <p class="">Age</p>
          </th>
          <th
            @click="teamFormationDropdown = !teamFormationDropdown"
            class="w-72"
          >
            <p class="">CV</p>
          </th>

          <th class="w-24">Action</th>
        </tr>
      </thead>

      <tbody
        v-for="memberData in memberDatas"
        :key="memberData.memberID"
        class="text-left"
      >
        <tr class="border-b-2">
          <td class="text-left p-3">
            <label
              class="text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"
            >
              <input
                type="checkbox"
                class="form-checkbox rowCheckbox focus:outline-none focus:shadow-outline"
                :name="memberData.memberID"
                @click="getRowDetail($event, memberData.memberID)"
              />
            </label>
          </td>
          <td>
            <div class="flex flex-row">
              <!-- in openmemberDropdown there will be an id -->
              <span
                @click="memberDetails(memberData.memberID)"
                class="cursor-pointer text-sm text-gray-500 flex"
              >
                🔻

                <div class="flex flex-col ml-2">
                  <span class="text-sm font-medium text-gray-900">
                    {{ memberData.fullName }}
                  </span>
                </div>
              </span>
            </div>
          </td>

          <!-- <td class="text-left">Nihat Muradzade</td>
            <td class="text-left">nihatmuradzade@gmail.com</td>
-->

          <td class="rounded-full text-black text-left font-bold">
            <!-- <div class="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div> -->

            {{ memberData.position }}
          </td>
          <td class="text-left">
            {{ memberData.email }}
          </td>
          <td class="text-left">
            <p
              contenteditable
              @input="handleInput"
              @click="getID(memberData.memberID)"
            >
              {{ memberData.phoneNumber }}
            </p>
          </td>
          <td contenteditable @input="handleInput" class="text-left">
            {{ memberData.age }}
          </td>
          <td contenteditable class="text-left">Talifa_CV</td>

          <td class="text-left text-custom-blue underline underline-offset-2">
            <button @click="editUser()">Edit</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- table for teams -->
    <table v-if="isMember == false" class="table-auto justify-between">
      <thead class="w-screen">
        <tr class="text-left justify-evenly border-b-2 bg-gray-100">
          <th
            class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100"
          >
            <label
              class="text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"
            >
              <input
                type="checkbox"
                class="form-checkbox focus:outline-none focus:shadow-outline"
                @click="selectAllCheckboxes($event)"
              />
            </label>
          </th>
          <th class="w-72">Team name</th>
          <th class="w-72" @click="statusDropdown = !statusDropdown">
            <p
              v-if="statusText == true"
              class="text-sm text-gray-500 font-light"
            >
              Status
            </p>
            <select
              @click="
                isOpenStatus = false;
                statusText = true;
              "
              v-model="selectedCategoryStatus"
            >
              <option value="All">Status</option>
              <option value="Active">Active</option>
              <option value="Passive">Passive</option>
            </select>
          </th>

          <th @click="lastMeetingDropdown = !lastMeetingDropdown" class="w-72">
            <p
              v-if="lastMeetingText == true"
              class="text-sm text-gray-500 font-light"
            >
              Last meeting
            </p>
            <select
              @click="
                isOpen = false;
                lastMeetingText = true;
              "
              name="category"
              id="category"
            >
              <option value="">Last Meeting</option>
              <option value="">More than 1 week</option>
              <option>More than 2 week</option>
              <option>Less than 1 week</option>
            </select>
          </th>
          <th @click="cvStageDropdown = !cvStageDropdown" class="w-72">
            <p
              v-if="cvStageText == true"
              class="text-sm text-gray-500 font-light"
            >
              CV stage
            </p>
            <select
              @click="
                isOpen = false;
                cvStageText = true;
              "
              v-model="selectedCategoryCV"
            >
              <option value="All">CV stage</option>
              <option value="Accepted">Accepted</option>
              <option value="Pending">Pending</option>
              <option value="Rejected">Rejected</option>
            </select>
          </th>
          <th
            @click="interviewStageDropdown = !interviewStageDropdown"
            class="w-72"
          >
            <p
              v-if="interviewStageText == true"
              class="text-sm text-gray-500 font-light"
            >
              Interview stage
            </p>
            <select
              @click="
                isOpen = false;
                interviewStageText = true;
              "
              name="category"
              id="category"
            >
              <option value="">Interview stage</option>
              <option>Accepted</option>
              <option>Pending</option>
            </select>
          </th>
          <th
            @click="teamFormationDropdown = !teamFormationDropdown"
            class="w-72"
          >
            <p
              v-if="teamFormationText == true"
              class="text-sm text-gray-500 font-light"
            >
              Team formation status
            </p>
            <select
              @click="
                isOpen = false;
                teamFormationText = true;
              "
              name="category"
              id="category"
            >
              <option value="">Team formation status</option>
              <option>Pending</option>
              <option>Done</option>
            </select>
          </th>
          <th
            @click="teamIndividualDropdown = !teamIndividualDropdown"
            class="w-72"
          >
            <p
              v-if="teamIndividualText == true"
              class="text-sm text-gray-500 font-light"
            >
              Team or individual
            </p>
            <select
              @click="
                isOpen = false;
                teamIndividualText = true;
              "
              name="category"
              id="category"
            >
              <option value="">Team/ Individual</option>
              <option>Team</option>
              <option>Individual</option>
            </select>
          </th>
          <th class="w-24">Action</th>
        </tr>
      </thead>

      <tbody
        v-for="teamData in teamDatas"
        :key="teamData.teamID"
        class="text-left"
      >
        <tr class="border-b-2">
          <td class="text-left p-3">
            <label
              class="text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"
            >
              <input
                type="checkbox"
                class="form-checkbox rowCheckbox focus:outline-none focus:shadow-outline"
                :name="teamData.teamID"
                @click="getRowDetail($event, teamData.teamID)"
              />
            </label>
          </td>
          <td>
            <div class="flex flex-row">
              <!-- in openTeamDropdown there will be an id -->
              <span
                @click="teamDetails(teamData.teamID)"
                class="cursor-pointer text-sm text-gray-500 flex"
              >
                🔻

                <div class="flex flex-col ml-2">
                  <span class="text-sm font-medium text-gray-900">
                    {{ teamData.teamName }}
                  </span>
                </div>
              </span>
            </div>
          </td>

          <!-- <td class="text-left">Nihat Muradzade</td>
            <td class="text-left">nihatmuradzade@gmail.com</td>
-->

          <td
            class="rounded-full text-black text-left font-bold"
            :class="changeStatusColor(teamData.status)"
          >
            <!-- <div class="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div> -->

            {{ teamData.status }}
          </td>
          <td class="text-left">2022-11-21</td>
          <td class="text-left">
            <p
              contenteditable
              @input="handleInput"
              @click="getID(teamData.teamID)"
            >
              {{ teamData.CVstatus }}
            </p>
          </td>
          <td contenteditable @input="handleInput" class="text-left">
            {{ teamData.interviewStatus }}
          </td>
          <td contenteditable class="text-left">
            {{ teamData.teamFormationStatus }}
          </td>
          <td contenteditable class="text-left">{{ teamData.teamSize }}</td>

          <td class="text-left text-custom-blue underline underline-offset-2">
            <button @click="editUser()">Edit</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import TSSearchBar from "@/components/TrackingSystem/TSSearchBar.vue";
export default {
  name: "MentorPage",
  data() {
    return {
      posts: [],
      maxChar: 100,
      minChar: 1,
      counter: 0,
      editUserModal: false,
      date: "",
      dropdown: false,
      selectedMail: "",
      selectedTeamStatus: "",
      selectedStatus: "",
      value: "",
      rowData: [],
      shortFeedback: "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
      showFeedbacks: false,
      teamName: "",
      search: "",
      statusDropdown: false,
      statusText: false,
      teamIndividualDropdown: false,
      teamIndividualText: false,
      teamFormationDropdown: false,
      teamFormationText: false,
      interviewStageDropdown: false,
      interviewStageText: false,
      cvStageDropdown: false,
      cvStageText: false,
      lastMeetingDropdown: false,
      lastMeetingText: false,
      meetingDiscord: "",
      meetingEmail: "",
      selectedCategoryStatus: "All",
      selectedCategoryCV: "All",
      selectedCategoryInterviewStage: "All",
      teamDatas: this.teamDatas,
      selectedRows: [],
      teamData: "",
      cvStageTextNew: "",
      name: "",
      teamID: "",
      changesArr: [],
      CVstatus: "",
      notFound: false,
      email: "",
      isMember: false,
      getTeamDetails: false,
    };
  },

  computed: {
    // filterData: function () {
    //   var vm = this;
    //   var status = vm.selectedCategoryStatus;
    //   var cv = vm.selectedCategoryCV;
    //   if (status === "All" && cv === "All") {
    //     return vm.teamDatas;
    //   } else if (status === "All") {
    //     return vm.teamDatas.filter(function (teamDatas) {
    //       return teamDatas.CVstatus === cv;
    //     });
    //   } else if (cv === "All") {
    //     return vm.teamDatas.filter(function (teamDatas) {
    //       return teamDatas.status === status;
    //     });
    //   } else {
    //     return vm.teamDatas.filter(function (teamDatas) {
    //       return teamDatas.status === status && teamDatas.CVstatus === cv;
    //     });
    //   }
    // },
  },

  mounted() {
    this.searchTeam();
  },
  methods: {
    async teamDetails(teamID) {
      //console.log(mentorId);
      await axios
        .get(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/tracking/team" +
            teamID +
            "/"
        )
        .then((response) => {
          this.teamData = response.data;
          console.log(this.teamData);
          console.log(teamID);

          this.$router.push({
            path: "/teamDetails/" + this.fullname,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    changeStatusColor(status) {
      if (status == "Active") {
        return "text-green-500";
      } else if (status == "Yellow") {
        return "text-yellow-500";
      } else if (status == "Passive") {
        return "text-red-500";
      }
    },

    searchTeam() {
      this.currentURL = window.location.href;
      console.log(this.currentURL);
      let counter = 0;
      let i = 0;
      while (counter < 4) {
        i++;
        if (this.currentURL[i] === "/") {
          counter++;
        }
      }
      let str = "";
      if (this.currentURL[this.currentURL.length] === "/")
        str = this.currentURL.slice(i + 1, this.currentURL.length - 1);
      else str = this.currentURL.slice(i + 1, this.currentURL.length);

      axios
        .get(
          `https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/tracking/search/all/` +
            str
        )
        .then((res) => {
          console.log(res.data);
          res.data.members;
          this.memberDatas = res.data.members;
          console.log(this.memberDatas);

          let key = "";
          let finalarr1 = [];
          console.log(res.data.teams);
          for (key in res.data.teams) {
            if (Object.hasOwnProperty.call(res.data.teams, key)) {
              const element = res.data.teams[key];
              if (element.length > 0) {
                finalarr1 = finalarr1.concat(element);
              }
            }
          }

          // let finalarr2;
          this.teamDatas = res.data.teams;
          console.log(this.teamDatas);
          // res.data.forEach((element) => {
          //   if (element.length > 0) {
          //     this.teamDatas = this.teamData.concat(element);
          //   }
          // });
          // if (this.teamData.length === 0) {
          //   this.notFound == true;
          // }

          // check if memberDatas is null or not

          if (this.memberDatas.length === 0) {
            this.isMember = false;
          } else if (this.memberDatas.length > 0) {
            this.isMember = true;
          } else if (this.teamDatas.length === 0) {
            this.isMember = true;
          } else if (this.teamDatas.length > 0) {
            this.isMember = false;
          }

          // if (this.memberDatas.length === 0 && this.teamDatas.length === 0) {
          //   this.notFound = true;
          //   console.log("not found");
          // } else {
          //   this.notFound = false;
          // }

          // if mentorData is empty then notFound is true

          if (this.teamDatas.length === 0) {
            this.notFound = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  components: {
    TSSearchBar,
    // DropDownMenu,
  },
};
</script>

<style></style>
