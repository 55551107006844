<template>
  <div
    class="pt-11 md:w-auto xl:w-auto mx-auto flex items-stretch justify-between flex-row col-end-1 md:px-32"
  >
    <div
      class="hidden md:visible w-full md:w-1/2 lg:w-full xl:w-1/2 md:mb-14 xl:mb-0 relative md:flex flex-col items-center justify-center"
    >
      <img
        src="../../public/logoSVG.svg"
        alt=""
        class="absolute z-20 w-10/12 pb-8"
      />

      <!-- <p class="bt-md">Welcome to the SABAH.incubation online library</p> -->
    </div>
    <div
      class="w-full xl:w-9/12 align-middle flex flex-col justify-center items-center"
    >
      <div class="md:shadow-xl rounded-2xl w-full md:w-8/12 px-14 bg-bg-blue">
        <div class="flex flex-col">
          <p class="text-4xl font-medium pb-11 pt-8">Login</p>

          <div class="pb-12">
            <div class="relative">
              <input
                v-model="email"
                type="email"
                class="shadow-sm block border-0 p-2.5 px-6 w-full h-12 z-20 text-sm text-gray-900 bg-white rounded-2xl"
                placeholder="Email"
              />
              <button
                type="submit"
                class="absolute top-0 right-0 py-2.5 px-4 h-12 duration-300"
              >
                <img src="../assets/Message.svg" alt="" />
              </button>
            </div>

            <div class="py-2"></div>

            <div class="relative pb-10">
              <input
                :type="passwordFieldType"
                v-model="password"
                class="shadow-sm block border-0 p-2.5 px-6 w-full h-12 z-20 text-sm text-gray-900 bg-white rounded-2xl"
                placeholder="Password"
              />

              <button
                @click="showPassword"
                type="submit"
                class="absolute top-0 right-0 py-2.5 px-4 h-12 duration-300"
              >
                <img src="../assets/Show.svg" alt="" />
              </button>
            </div>

            <button
              @click="login()"
              class="shadow-md h-11 w-full rounded-2xl text-gray-50 bg-custom-blue hover:shadow-lg duration-300 bt-book px-2 py-1.5"
            >
              Login
            </button>

            <!-- <div class="relative flex py-2.5 items-center px-20">
              <div class="flex-grow border-t border-gray-200"></div>
              <span class="flex-shrink mx-2 text-gray-400">Or</span>
              <div class="flex-grow border-t border-gray-200"></div>
            </div> -->

            <!-- <div class="flex justify-center items-center pb-8">
              <p class="py-6 px-2.5">Have no account yet?</p>
              <a
                href="/signup"
                class="underline underline-offset-2 text-custom-blue"
                >Register</a
              >
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../utilities/firebase";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default {
  name: "HomePage",
  data() {
    return {
      toastMessage: "",
      toastColor: "",
      firstName: "",
      lastName: "",
      passwordFieldType: "password",
    };
  },
  setup() {
    const toast = useToast();
    const router = useRouter();
    const email = ref("");
    const password = ref("");
    const role = ref("");
    const confirmPassword = ref("");

    return {
      role,
      toast,
      router,
      email,
      password,
      confirmPassword,
    };
  },

  computed: {
    comparePasswords() {
      return this.password !== this.confirmPassword
        ? "Passwords do not match"
        : true;
    },
  },

  methods: {
    showErrorToast(message) {
      this.toast.error(message, {
        position: "top-right",
        duration: 3000,
        action: {
          text: "Dismiss",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },

    showToast() {
      this.toast.success("Logged in successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },

    showPassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },

    login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          localStorage.setItem("userEmail", this.email);
          this.showToast();
          this.$router.push("/");
        })
        .catch((error) => {
          this.showErrorToast(error.message);
        });
    },
  },
};
</script>

<style></style>
