<template>
  <div>
    <p>RANDOM TEXT</p>
  </div>
</template>

<script>
export default {
    
};
</script>

<style></style>