import { createRouter, createWebHistory } from "vue-router";
import homePage from "../pages/homePage.vue";
import postDetails from "../pages/postDetails";
import bookDetails from "../pages/bookDetails";
import videoDetails from "../pages/videoDetails";
import login from "../pages/Login";
import aboutus from "../pages/aboutUs";
import takealook from "../pages/takeAlook";
import { getAuth } from "firebase/auth";
import searchCat from "../pages/searchCat";
import searchSubcat from "../pages/searchSubcat";
import Search from "../pages/SearchContent.vue";
import landingPage from "../pages/landingPage.vue";
import applicationForm from "../pages/applicationForm.vue";

//Tracking system
import TSlandingPage from "../pages/TrackingSystem/TSlandingPage.vue";
import SeeStats from "../pages/TrackingSystem/SeeStats.vue";
// import TSSearchBar from "../components/TrackingSystem/TSSearchBar.vue";
import SearchResults from "../pages/TrackingSystem/SearchResults.vue";

const routes = [
  {
    path: "/",
    name: "homePage",
    component: homePage,
  },

  {
    path: "/landingPage",
    name: "landingPage",
    component: landingPage,
  },

  {
    path: "/postDetails/:id",
    name: "postDetails",
    component: postDetails,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bookDetails/:id",
    name: "bookDetails",
    component: bookDetails,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/videoDetails/:id",
    name: "videoDetails",
    component: videoDetails,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/search/:search",
    name: "Search",
    component: Search,
  },

  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: aboutus,
  },
  {
    path: "/takealook",
    name: "takealook",
    component: takealook,
  },
  {
    path: "/categories/:str",
    name: "searchCat",
    component: searchCat,
  },
  {
    path: "/subcategories/:str/:str2",
    name: "searchSubcat",
    component: searchSubcat,
  },

  //Tracking system
  {
    path: "/TSlandingPage",
    name: "TSlandingPage",
    component: TSlandingPage,
  },
  {
    path: "/SeeStats",
    name: "SeeStats",
    component: SeeStats,
  },
  {
    path: "/application",
    name: "applicationForm",
    component: applicationForm,
   



  },
  // {
  //   path: "/TSsearch/:search",
  //   name: "SearchBar",
  //   component: TSSearchBar,
  // },
  {
    path: "/TSsearchResults/:search",
    name: "SearchResults",
    component: SearchResults,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

// router.afterEach(() => {
//   document.getElementById("app").scrollIntoView();
// });

// Navigation Guards
// router.beforeRouteUpdate((to, from, next) => {
//   if (to.name === "postDetails") {
//     next();
//   } else {
//     next({ name: "postDetails" });
//   }
// });

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (getAuth().currentUser) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
  // if (to.name === "postDetails") {
  //   next();
  //   return;
  // } else {
  //   next({ name: "postDetails" });
  //   return;
  // }
});

export default router;
