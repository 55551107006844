<template>
  <div class="flex-wrap py-2 justify-start">
    <ul
      class="inline-flex py-2 text-sm sm:text-sm px-2"
      v-for="tag in searchTags"
      :key="tag.id"
    >
      <li
        @click="searchTag(tag)"
        class="bg-tag-blue cursor-pointer text-xs px-2 rounded w-max sm:text-sm hover:bg-custom-blue hover:text-white duration-300"
      >
        {{ tag }}
      </li>
    </ul>
    <!-- <span class="">
      <button
        @click="mentorPage()"
        class="text-custom-blue underline underline-offset-2 inline-flex"
      >
        See more
        <img src="../assets/ArrowSmall.svg" alt="" class="p-2" />
      </button>
    </span> -->
  </div>
</template>

<script>
// import "../assets/main.css";
export default {
  props: {
    searchTags: [],
  },
  data() {
    return {
      candData: {},
      isLoading: true,
      candDatas: [],
      tags: this.searchTags,
      search: "",
    };
  },

  beforeUpdate() {
    console.log(this.searchTags);
  },

  methods: {
    searchTag(searchtag) {
      // this.$router.push("/search/" + searchtag);
      window.location.href = "/search/" + searchtag;
    },
    mentorPage() {
      this.$router.push("/");
    },
  },
};
</script>

<style></style>
