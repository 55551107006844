<template>
  <!-- <div>Tracking system</div> -->

  <div class="px-6 h-screen">
    <div class="flex flex-col md:flex-row justify-between align-middle">
      <div class="flex flex-col">
        <button class="px-4">
          <div class="flex flex-col">
            <label for="email" class="text-sm text-gray-500"
              >Contact options</label
            >
            <div class="flex space-x-4">
              <select v-model="selectedMail" class="border-2 rounded-xl px-2">
                <option disabled value="">Please select one</option>

                <option value="reject">Reject</option>
                <option value="acceptCV">Accept CV Stage</option>
                <option value="accept">Accept Interview stage</option>
                <option value="setMeeting">Set meeting email</option>
                <option value="setMeetingDiscord">Set meeting Discord</option>
                <option value="blank">Blank Email</option>
              </select>
            </div>
            <div v-if="selectedMail == 'setMeeting'" class="py-2">
              <textarea
                v-model="meetingEmail"
                name=""
                id=""
                cols="30"
                rows="3"
                placeholder="Ex. Tuesday 14:00-15:00"
                class="border-2 border-gray-300 rounded-md p-2"
              >
              </textarea>
            </div>
            <div v-if="selectedMail == 'setMeetingDiscord'" class="py-2">
              <textarea
                v-model="meetingDiscord"
                name=""
                id=""
                cols="30"
                rows="3"
                placeholder="Ex. Tuesday 14:00-15:00"
                class="border-2 border-gray-300 rounded-md p-2"
              >
              </textarea>
            </div>
            <div v-if="selectedMail == 'blank'" class="py-2">
              <textarea
                v-model="meetingEmail"
                name=""
                id=""
                cols="30"
                rows="3"
                placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit."
                class="border-2 border-gray-300 rounded-md p-2"
              >
              </textarea>
            </div>
            <!-- <div
                          v-if="selectedMail == 'setMeetingDiscord'"
                          class="py-2"
                        >
                          <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="3"
                            placeholder="Ex. Tuesday 14:00-15:00"
                            class="border-2 border-gray-300 rounded-md p-2"
                          >
                          </textarea>
                        </div> -->
          </div>
        </button>

        <div class="align-baseline px-4 pt-6 text-right">
          <button
            @click="sendEmails(teamData.teamID)"
            class="bg-custom-blue border-custom-blue px-4 rounded-xl text-white w-full"
          >
            <p class="px-2 py-1.5">Save</p>
          </button>
        </div>
      </div>
      <div class="w-6/12">
        <TSSearchBar />
      </div>

      <span>
        <button
          @click="goToStats()"
          class="flex justify-center w-52 bg-custom-blue text-white border-2 border-custom-blue hover:bg-custom-blue hover:text-white duration-300 px-2 py-1.5 rounded-2xl"
        >
          <img src="../../assets/stats.svg" class="px-2 w-8" alt="" />
          <p class="px-2">Statistics</p>
        </button>
      </span>
    </div>

    <div class="flex flex-col pt-8">
      <!-- create a table with names -->

      <div
        class="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        v-if="dropdown"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <!--
            Background overlay, show/hide based on modal state.
      
            Entering: "ease-out duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100"
              To: "opacity-0"
          -->
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <!--
            Modal panel, show/hide based on modal state.
      
            Entering: "ease-out duration-300"
              From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              To: "opacity-100 translate-y-0 sm:scale-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100 translate-y-0 sm:scale-100"
              To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          -->

          <div
            class="inline-block align-bottom rounded-lg text-left overflow-hidden sm:my-8 sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            v-if="dropdown"
          >
            <div v-for="members in teamDetailsData" :key="members">
              <div v-for="member in members" :key="member">
                <p>{{ member.fullName }}</p>
              </div>
            </div>
            <TSteamDetails
              @close="closeModal"
              :teamID="this.teamDetailsData.teamID"
            />

            <!-- <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:mt-0 w-full sm:text-left">
                  <div class="flex justify-between">
                    <h3
                      class="text-lg leading-6 font-medium text-gray-900"
                      id="modal-headline"
                    >
                      Details of {{ teamDetailsData.teamName }} Long
                    </h3>
                    <button @click="this.dropdown = false">❌</button>
                  </div>

                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      See details and edit the team's information
                    </p>
                    <br />

                    <div class="flex flex-col pb-2">
                      <div class="table-auto justify-between">
                        <div class="text-left">
                          <table
                            class="table-auto w-full justify-between divide-y-2"
                          >
                            <thead>
                              <tr class="text-left">
                                <th>Team members</th>
                                <th>Position</th>
                                <th>Email Address</th>
                                <th>Phone numbers</th>
                                <th>CV</th>
                                <th>Remove</th>
                              </tr>
                            </thead>
                            <tbody v-for="members in memberData" :key="members">
                              <tr>
                                <td class="text-left">
                                  <input
                                    type="text"
                                    class="w-48"
                                    placeholder="Fullname"
                                    v-model="members.fullName"
                                  />
                                </td>
                                <td class="text-left">
                                  <input
                                    type="text"
                                    class="w-48"
                                    placeholder="Position"
                                    v-model="members.position"
                                  />
                                </td>
                                <td class="text-left">
                                  <input
                                    type="text"
                                    class="w-48"
                                    placeholder="Email address"
                                    v-model="members.email"
                                  />
                                </td>
                                <td class="text-left">
                                  <input
                                    type="text"
                                    class="w-48"
                                    placeholder="Phone number"
                                    v-model="members.phoneNumber"
                                  />
                                </td>

                                <td
                                  class="text-left text-custom-blue underline underline-offset-2"
                                >
                                  nihat_cv
                                </td>
                                <td>
                                  <button
                                    class="bg-red-500 hover:bg-red-700 text-white font-bold rounded px-2"
                                    @click="removeMember(members)"
                                  >
                                    -
                                  </button>
                                </td>
                              </tr>
                            </tbody>

                            <tbody>
                              <tr>
                                <td class="text-left">
                                  <input type="text" placeholder="Fullname" />
                                </td>
                                <td class="text-left">
                                  <input type="text" placeholder="Position" />
                                </td>
                                <td class="text-left">
                                  <input type="text" placeholder="Email" />
                                </td>
                                <td class="text-left">
                                  <input
                                    type="text"
                                    placeholder="Phone number"
                                  />
                                </td>

                                <td
                                  class="text-left text-custom-blue underline underline-offset-2"
                                >
                                  <input type="text" placeholder="CV link" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div
                            class="flex justify-between text-right border-t-2 pt-8"
                          >
                            <p class="font-bold">Files</p>
                            <div
                              class="flex flex-col text-custom-blue underline underline-offset-2"
                            >
                              <button><p class="">Files of the team</p></button>
                            </div>
                          </div>

                          <div class="flex justify-between border-y-2">
                            <p class="font-bold">Status:</p>
                            <div
                              class="rounded-full text-white text-left font-bold"
                              :class="changeStatusColor(teamDetailsData.status)"
                            >
                              {{ teamDetailsData.status }}
                            </div>
                          </div>

                          <div
                            class="flex justify-between text-right border-b-2"
                          >
                            <p class="font-bold">Last Meeting:</p>
                            <div class="flex flex-col">
                              <p class="">{{ lastMeeting }}</p>
                            </div>
                          </div>
                          <div
                            class="flex justify-between text-left border-b-2"
                          >
                            <p class="font-bold">Online library credentials</p>
                            <div class="flex flex-col">
                              <p class="">Created</p>
                            </div>
                          </div>
                          <div
                            class="flex justify-between text-right border-b-2"
                          >
                            <p class="font-bold">Give feedback:</p>
                            <div class="flex flex-col py-2">
                              <textarea
                                type="text"
                                class="w-48 border-2 rounded-2xl px-1.5 py-1.5"
                                placeholder="Short feedback"
                                v-model="shortFeedback"
                              />
                            </div>
                          </div>
                          <div
                            @click="this.showFeedbacks = !this.showFeedbacks"
                            class="cursor-pointer flex justify-between text-right border-b-2"
                          >
                            <p class="font-bold">Team Feedback:</p>
                            <div class="flex flex-col">
                              <p class="">Click to see weekly feedbacks</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="this.showFeedbacks" class="overflow-auto">
                      <table class="w-full table-auto table-striped">
                        <thead class="overflow-hidden">
                          <tr
                            class="text-left flex space-x-4 border-b-2 p-2 leading-4 text-gray-500 uppercase tracking-wider"
                          >
                            <th class="w-32">Week 1</th>
                            <th class="w-32">Week 2</th>
                            <th class="w-32">Week 3</th>
                            <th class="w-32">Week 4</th>
                            <th class="w-32">Week 5</th>
                            <th class="w-32">Week 6</th>
                            <th class="w-32">Week 7</th>
                            <th class="w-32">Week 8</th>
                            <th class="w-32">Week 9</th>
                            <th class="w-32">Week 10</th>
                            <th class="w-32">Week 11</th>
                            <th class="w-32">Week 12</th>
                          </tr>
                        </thead>
                    
                        <tbody class="overflow-x-hidden">
                          <tr class="border-b-2 divide-x-2 flex">
                       
                            <td contenteditable class="text-left p-2">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Optio quae minima velit blanditiis dolor
                              unde?
                            </td>

                            <td contenteditable class="text-left p-2">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Optio quae minima velit blanditiis dolor
                              unde?
                            </td>
                            <td contenteditable class="text-left p-2">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Optio quae minima velit blanditiis dolor
                              unde?
                            </td>
                            <td contenteditable class="text-left p-2">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Optio quae minima velit blanditiis dolor
                              unde?
                            </td>
                            <td contenteditable class="text-left p-2">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Optio quae minima velit blanditiis dolor
                              unde?
                            </td>
                            <td contenteditable class="text-left p-2">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Optio quae minima velit blanditiis dolor
                              unde?
                            </td>
                            <td contenteditable class="text-left p-2">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Optio quae minima velit blanditiis dolor
                              unde?
                            </td>
                            <td contenteditable class="text-left p-2">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Optio quae minima velit blanditiis dolor
                              unde?
                            </td>
                            <td contenteditable class="text-left p-2">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Optio quae minima velit blanditiis dolor
                              unde?
                            </td>
                            <td contenteditable class="text-left p-2">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Optio quae minima velit blanditiis dolor
                              unde?
                            </td>
                            <td contenteditable class="text-left p-2">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Optio quae minima velit blanditiis dolor
                              unde?
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="flex flex-col py-8">
                      <label for="email" class="text-sm font-bold"
                        >Team Status</label
                      >
                      <div class="flex space-x-4">
                        <button>
                          <p
                            class="focus:border-2 focus:border-custom-blue"
                            value="red"
                          >
                            🔴
                          </p>
                        </button>
                        <button>
                          <p
                            class="focus:border-2 focus:border-custom-blue"
                            value="red"
                          >
                            🟡
                          </p>
                        </button>
                        <button>
                          <p
                            class="focus:border-2 focus:border-custom-blue"
                            value="green"
                          >
                            🟢
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="pt-8 flex justify-between">
                    <div>
                      <button
                        class="px-4 py-2 hover:shadow-xl hover:font-bold duration-300 rounded-2xl bg-custom-blue text-white"
                      >
                        Save
                      </button>
                    </div>
                    <div>
                      <button
                        @click="deleteTeam()"
                        class="bg-red-500 text-white px-4 py-2 hover:shadow-xl hover:font-bold duration-300 rounded-2xl"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <!-- editUserModal -->
      <div
        class="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        v-if="editUserModal"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <!--
            Background overlay, show/hide based on modal state.
      
            Entering: "ease-out duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100"
              To: "opacity-0"
          -->
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <!--
            Modal panel, show/hide based on modal state.
      
            Entering: "ease-out duration-300"
              From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              To: "opacity-100 translate-y-0 sm:scale-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100 translate-y-0 sm:scale-100"
              To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          -->
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-auto sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:mt-0 sm:text-left">
                  <div class="flex justify-between">
                    <h3
                      class="text-lg leading-6 font-medium text-gray-900"
                      id="modal-headline"
                    >
                      Details of {{ teamDetailsData.teamName }}
                    </h3>
                    <button
                      @click="
                        this.editUserModal = false;
                        $emit('close');
                      "
                    >
                      ❌
                    </button>
                  </div>

                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      See details and edit the team's information
                    </p>
                    <br />
                    <div class="space-y-4">
                      <div class="flex flex-col">
                        <label for="name" class="text-sm text-gray-500"
                          >Last meeting</label
                        >
                        <input
                          type="text"
                          name="lastMeeting"
                          id="lastMeeting"
                          v-model="lastMeeting"
                          class="border-2 border-gray-300 rounded-md p-2"
                        />
                      </div>

                      <div class="flex flex-col">
                        <label for="email" class="text-sm text-gray-500"
                          >Contact options</label
                        >
                        <div class="flex space-x-4">
                          <select
                            v-model="selectedMail"
                            class="border-2 rounded-2xl px-2"
                          >
                            <option disabled value="">Please select one</option>

                            <option value="reject">Reject</option>
                            <option value="accept">Accept CV Stage</option>
                            <option value="accept">
                              Accept Interview stage
                            </option>
                            <option value="setMeeting">
                              Set meeting email
                            </option>
                            <option
                              class="flex items-center"
                              value="setMeetingDiscord"
                            >
                              Set meeting Discord
                            </option>
                          </select>
                        </div>
                        <div v-if="selectedMail == 'setMeeting'" class="py-2">
                          <textarea
                            v-model="meetingEmail"
                            name=""
                            id=""
                            cols="30"
                            rows="3"
                            placeholder="Ex. Tuesday 14:00-15:00"
                            class="border-2 border-gray-300 rounded-md p-2"
                          >
                          </textarea>
                        </div>
                        <div
                          v-if="selectedMail == 'setMeetingDiscord'"
                          class="py-2"
                        >
                          <textarea
                            v-model="meetingDiscord"
                            name=""
                            id=""
                            cols="30"
                            rows="3"
                            placeholder="Ex. Tuesday 14:00-15:00"
                            class="border-2 border-gray-300 rounded-md p-2"
                          >
                          </textarea>
                        </div>
                        <!-- <div
                          v-if="selectedMail == 'setMeetingDiscord'"
                          class="py-2"
                        >
                          <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="3"
                            placeholder="Ex. Tuesday 14:00-15:00"
                            class="border-2 border-gray-300 rounded-md p-2"
                          >
                          </textarea>
                        </div> -->
                      </div>

                      <div class="flex flex-col">
                        <label for="email" class="text-sm text-gray-500"
                          >Status (Actvity status)</label
                        >
                        <div class="flex space-x-4">
                          <button @click="sendStatusEmail('red')">🔴</button>
                          <button @click="sendStatusEmail('yellow')">🟡</button>
                          <button @click="sendStatusEmail('green')">🟢</button>
                        </div>
                        <!-- <input
                        type="text"
                        name="email"
                        id="email"
                        v-model="editUser.email"
                        class="border-2 border-gray-300 rounded-md p-2"
                      /> -->
                      </div>
                    </div>
                  </div>
                  <div class="pt-8">
                    <button
                      class="px-2 py-1.5 rounded-2xl bg-custom-blue text-white"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="filter">
          <p class="text-left text-sm text-gray-400 pb-2">Country</p>

          <select
            class="text-white bg-custom-blue hover:bg-cyan-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            v-model="selectedCategoryLocation"
          >
            <option value="All">Please select one</option>
            <option
              v-for="locations in locFilter"
              :key="locations.id"
              :value="locations"
            >
              {{ locations }}
            </option>
          </select>
        </div> -->
      <!-- <div
        class="flex flex-row justify-between items-center"
        v-for="teamData in teamDatas"
        :key="teamData"
      >
        <p>{{ teamData.teamName }}</p>
      </div> -->
      <div
        v-show="selectedRows.length"
        class="bg-custom-blue fixed top-0 left-0 right-0 z-40 w-full shadow"
      >
        <div class="container mx-auto px-4 py-4">
          <div class="flex md:items-center">
            <div class="mr-4 flex-shrink-0">
              <svg
                class="h-8 w-8 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="text-white text-lg">
              <p v-if="selectedRows.length > 0">
                {{ selectedRows.length }} rows selected
              </p>
            </div>
          </div>
        </div>
      </div>

      <table class="table-auto justify-between">
        <thead class="w-screen">
          <tr class="text-left justify-evenly border-b-2 bg-gray-100">
            <th
              class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100"
            >
              <label
                class="text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="form-checkbox focus:outline-none focus:shadow-outline"
                  @click="selectAllCheckboxes($event, teamData.teamID)"
                />
              </label>
            </th>
            <th class="w-72">Team name</th>
            <th class="w-72" @click="statusDropdown = !statusDropdown">
              <p
                v-if="statusText == true"
                class="text-sm text-gray-500 font-light"
              >
                Status
              </p>
              <select
                @click="
                  isOpenStatus = false;
                  statusText = true;
                "
                v-model="selectedCategoryStatus"
              >
                <option value="All">Status</option>
                <option value="Active">Active</option>
                <option value="Passive">Passive</option>
                <option value="Passive">Indefinite</option>
              </select>
            </th>

            <th
              @click="lastMeetingDropdown = !lastMeetingDropdown"
              class="w-72"
            >
              <p
                v-if="lastMeetingText == true"
                class="text-sm text-gray-500 font-light"
              >
                Last meeting
              </p>
              <select
                @click="
                  isOpen = false;
                  lastMeetingText = true;
                "
                name="category"
                id="category"
              >
                <option value="">Last Meeting</option>
                <option value="">More than 1 week</option>
                <option>More than 2 week</option>
                <option>More than 3 week</option>
                <option>Less than 1 week</option>
              </select>
            </th>
            <th @click="cvStageDropdown = !cvStageDropdown" class="w-72">
              <p
                v-if="cvStageText == true"
                class="text-sm text-gray-500 font-light"
              >
                Screening stage
              </p>
              <select
                @click="
                  isOpen = false;
                  cvStageText = true;
                "
                v-model="selectedCategoryCV"
              >
                <option value="All">Screening stage</option>
                <option value="Accepted">Accepted</option>
                <option value="Pending">Pending</option>
                <option value="Rejected">Rejected</option>
              </select>
            </th>
            <th
              @click="interviewStageDropdown = !interviewStageDropdown"
              class="w-72"
            >
              <p
                v-if="interviewStageText == true"
                class="text-sm text-gray-500 font-light"
              >
                MVP stage
              </p>
              <select
                @click="
                  isOpen = false;
                  interviewStageText = true;
                "
                v-model="selectedCategoryInterviewStage"
                name="category"
                id="category"
              >
                <option value="All">MVP stage</option>
                <option value="Done">Done</option>
                <option value="Pending">Pending</option>
                <option value="Rejected">Rejected</option>
              </select>
            </th>
            <th
              @click="teamFormationDropdown = !teamFormationDropdown"
              class="w-72"
            >
              <p
                v-if="teamFormationText == true"
                class="text-sm text-gray-500 font-light"
              >
                Team formation status
              </p>
              <select
                @click="
                  isOpen = false;
                  teamFormationText = true;
                "
                v-model="selectedCategoryTeamFormation"
                name="category"
                id="category"
              >
                <option value="All">Team formation status</option>
                <option value="Pending">Pending</option>
                <option value="Done">Done</option>
              </select>
            </th>
            <th
              @click="teamIndividualDropdown = !teamIndividualDropdown"
              class="w-72"
            >
              <p
                v-if="teamIndividualText == true"
                class="text-sm text-gray-500 font-light"
              >
                Team or individual
              </p>
              <select
                @click="
                  isOpen = false;
                  teamIndividualText = true;
                "
                v-model="selectedCategoryTeamIndividual"
                name="category"
                id="category"
              >
                <option value="All">Team/Individual</option>
                <option value="Team">Team</option>
                <option value="Individual">Individual</option>
              </select>
            </th>
          </tr>
        </thead>

        <tbody
          v-for="teamData in filterData"
          :key="teamData.teamID"
          class="text-left"
        >
          <tr class="border-b-2 odd:bg-white even:bg-slate-50">
            <td class="text-left p-3">
              <label
                class="text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="form-checkbox rowCheckbox focus:outline-none focus:shadow-outline"
                  :name="teamData.teamID"
                  @click="getRowDetail($event, teamData.teamID)"
                />
              </label>
            </td>
            <td>
              <div class="flex flex-row">
                <!-- in openTeamDropdown there will be an id -->
                <span
                  @click="teamDetails(teamData.teamID)"
                  class="cursor-pointer text-sm text-gray-500 flex"
                >
                  <img src="../../assets/downArrow.png" class="w-5" alt="" />

                  <div class="flex flex-col">
                    <span class="text-sm font-medium text-gray-900 truncate">
                      {{ teamData.teamName }}
                    </span>
                  </div>
                </span>
              </div>
            </td>
            <td
              class="rounded-full text-black text-left font-bold"
              :class="changeStatusColor(teamData.status)"
            >
              {{ teamData.status }}
            </td>

            <td class="text-left">
              <input
                type="text"
                v-model="teamData.lastMeeting"
                @input="handleInput($event, teamData.teamID, 'lastMeeting')"
                @click="getID(teamData.teamID, 'lastMeeting')"
                class="bg-transparent w-10/12"
              />
            </td>
            <td class="text-left">
              <input
                type="text"
                name=""
                id=""
                v-model="teamData.CVstatus"
                @input="handleInput($event, teamData.teamID, 'CV')"
                @click="getID(teamData.teamID, 'CV')"
                class="bg-transparent w-10/12"
              />
            </td>
            <td class="text-left">
              <input
                type="text"
                name=""
                id=""
                v-model="teamData.interviewStatus"
                @input="handleInput($event, teamData.teamID, 'MVPstage')"
                @click="getID(teamData.teamID, 'MVPstage')"
                class="bg-transparent w-10/12"
              />
            </td>
            <td class="text-left">
              <input
                type="text"
                name=""
                id=""
                v-model="teamData.teamFormationStatus"
                @input="handleInput($event, teamData.teamID, 'teamFormation')"
                @click="getID(teamData.teamID, 'teamFormation')"
                class="bg-transparent w-10/12"
              />

              <!-- <td contenteditable class="text-left">
              {{ teamData.teamFormationStatus }}
            </td> -->
            </td>
            <!-- 
            <td contenteditable class="text-left">{{ teamData.teamSize }}</td>
 -->

            <td class="text-left">
              <input
                type="text"
                name=""
                id=""
                v-model="teamData.teamSize"
                @input="handleInput($event, teamData.teamID, 'teamSize')"
                @click="getID(teamData.teamID, 'teamSize')"
                class="bg-transparent w-10/12"
              />

              <!-- <td contenteditable class="text-left">
              {{ teamData.teamFormationStatus }}
            </td> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TSSearchBar from "@/components/TrackingSystem/TSSearchBar.vue";
import TSteamDetails from "@/components/TrackingSystem/TSteamDetails.vue";
export default {
  data() {
    return {
      posts: [],
      maxChar: 100,
      minChar: 1,
      counter: 0,
      editUserModal: false,
      date: "",
      dropdown: false,
      selectedMail: "",
      selectedTeamStatus: "",
      selectedStatus: "",
      value: "",
      rowData: [],
      shortFeedback: "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
      showFeedbacks: false,
      teamName: "",
      search: "",
      statusDropdown: false,
      statusText: false,
      teamIndividualDropdown: false,
      teamIndividualText: false,
      teamFormationDropdown: false,
      teamFormationText: false,
      interviewStageDropdown: false,
      interviewStageText: false,
      cvStageDropdown: false,
      cvStageText: false,
      lastMeetingDropdown: false,
      lastMeetingText: false,
      meetingDiscord: "",
      meetingEmail: "",
      selectedCategoryStatus: "All",
      selectedCategoryCV: "All",
      selectedCategoryInterviewStage: "All",
      selectedCategoryTeamFormation: "All",
      selectedCategoryTeamIndividual: "All",
      teamDatas: [],
      selectedRows: [],
      teamData: "",
      cvStageTextNew: "",
      name: "",
      teamID: "",
      changesArr: [],
      CVstatus: "",
    };
  },
  components: {
    TSSearchBar,
    TSteamDetails,
  },

  computed: {
    filterData: function () {
      var vm = this;
      var status = vm.selectedCategoryStatus;
      var cv = vm.selectedCategoryCV;
      var mvp = vm.selectedCategoryInterviewStage;
      var teamFormation = vm.selectedCategoryTeamFormation;
      var teamIndividual = vm.selectedCategoryTeamIndividual;

      return vm.teamDatas.filter(function (teamDatas) {
        return (
          (status === "All" || teamDatas.status === status) &&
          (cv === "All" || teamDatas.CVstatus === cv) &&
          (mvp === "All" || teamDatas.interviewStatus === mvp) &&
          (teamFormation === "All" ||
            teamDatas.teamFormationStatus === teamFormation) &&
          (teamIndividual === "All" || teamDatas.teamSize === teamIndividual)
        );
      });
    },
  },

  mounted() {
    this.getTeamData();
    // this.currentDate();
  },
  methods: {
    closeModal() {
      this.dropdown = false;
    },

    getID(id, column) {
      console.log(id);
      this.teamID = id;
      console.log(column);
    },
    handleInput: function (e, teamID, value) {
      // this.teamData.interviewStatus = e.target.innerHTML;
      //set timeout
      console.log(teamID);
      console.log(value);
      console.log(e.target.innerHTML);
      for (let i = 0; i < this.teamDatas.length; i++) {
        if (this.teamDatas[i].teamID === teamID) {
          this.teamData = this.teamDatas[i];
          console.log(this.teamData);
        }
      }

      if (value === "CV") {
        this.CVstatus = this.teamData.CVstatus;
        console.log(this.CVstatus);
      }
      if (value === "lastMeeting") {
        this.lastMeeting = this.teamData.lastMeeting;
        console.log(this.lastMeeting);
      }

      if (value === "MVPstage") {
        this.interviewStage = this.teamData.interviewStage;
        console.log(this.interviewStage);
      }

      if (value === "teamFormation") {
        this.teamFormation = this.teamData.teamFormation;
        console.log(this.teamFormation);
      }

      if (value === "teamSize") {
        this.teamIndividual = this.teamData.teamSize;
        console.log(this.teamIndividual);
      }

      console.log(e.target.innerHTML);

      this.changesArr = [];
      let teamIDValue = teamID + "," + value;
      let teamIDValueE = teamIDValue + "," + e.target.innerHTML;

      this.changesArr.push(teamIDValueE);
      console.log(this.changesArr);

      this.cvStageTextNew = e.target.innerHTML;

      setTimeout(() => {
        this.saveChanges(teamID);
      }, 2000);
    },

    goToStats() {
      this.$router.push("/seeStats");
    },
    sendEmails(teamID) {
      let rows = this.selectedRows;
      console.log(rows);
      console.log(teamID);

      let emails = this.selectedMail;

      console.log(emails);
      axios
        .post(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/tracking/bulk-email/" +
            emails +
            "/",
          {
            teams_list: ["cf97b15f-80ea-41e4-aae7-d6ded4d7f744"],
          }
        )
        .then((response) => {
          this.emailResp = response.data;
        });
      console.log("Emails sent");
    },

    getRowDetail($event, id) {
      let rows = this.selectedRows;
      this.teamID = id;
      console.log(rows);
      this.selectedRows = rows;

      console.log(this.teamID);
      if (rows.includes(id)) {
        let index = rows.indexOf(id);
        rows.splice(index, 1);
      } else {
        rows.push(id);
      }
    },

    selectAllCheckboxes($event, id) {
      let columns = document.querySelectorAll(".rowCheckbox");
      this.teamID = id;
      console.log(this.teamID);
      // get the team id of all the rows

      this.teamDatas.forEach((element) => {
        this.teamID = element.teamID;
        console.log(this.teamID);
        this.selectedRows.push(this.teamID);
      });

      this.selectedRows = [];

      if ($event.target.checked == true) {
        columns.forEach((column) => {
          column.checked = true;
          this.selectedRows.push(parseInt(column.name));
        });
      } else {
        columns.forEach((column) => {
          column.checked = false;
        });
        this.selectedRows = [];
      }
    },

    async teamDetails(teamID) {
      // this.postId = this.$route.params.id;
      console.log(teamID);

      localStorage.setItem("teamID", teamID);
      axios
        .get(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/tracking/team/" +
            teamID +
            "/"
        )
        .then((response) => {
          this.teamDetailsData = response.data.team;

          this.memberData = response.data.members;
          console.log(this.teamDetailsData);
          console.log(this.teamDetailsData.teamName);
          console.log(this.memberData);

          // teamFeedback array
          for (let i = 0; i < this.teamDetailsData.teamFeedback.length; i++) {
            this.teamFeedback = this.teamDetailsData.teamFeedback[i];
            console.log(this.teamFeedback);
          }

          this.dropdown = !this.dropdown;
        });
    },

    async getTeamData() {
      // this.postId = this.$route.params.id;

      axios
        .get(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/tracking/teams"
        )
        .then((response) => {
          this.teamDatas = response.data;

          console.log(this.teamDatas);

          this.teamName = this.teamDatas.teamName;
          this.cvNew = this.teamDatas.CVstatus;

          console.log(this.teamDatas[0].lastMeeting);

          for (let i = 0; i < this.teamDatas.length; i++) {
            // convert 2000-01-01T00:00:00Z to 01/01/2000
            if (this.teamDatas[i].lastMeeting == null) {
              console.log("empty");
              this.teamDatas[i].lastMeeting = "NOT SET";
            }
            if (this.teamDatas[i].lastMeeting != null) {
              console.log("not empty");
              this.teamDatas[i].lastMeeting = this.teamDatas[
                i
              ].lastMeeting.substring(0, 10);
              this.teamDatas[i].lastMeeting = this.teamDatas[i].lastMeeting
                .split("-")
                .reverse()
                .join("/");
              console.log(this.teamDatas[i].lastMeeting);
            }
            // this.teamDatas[i].lastMeeting = this.teamDatas[
            //   i
            // ].lastMeeting.substring(0, 10);
            // this.teamDatas[i].lastMeeting = this.teamDatas[i].lastMeeting
            //   .split("-")
            //   .reverse()
            //   .join("/");
            // console.log(this.teamDatas[i].lastMeeting);
          }
        });
    },
    changeStatusColor(status) {
      if (status == "Active") {
        return "text-green-500";
      } else if (status == "Yellow") {
        return "text-yellow-500";
      } else if (status == "Passive") {
        return "text-red-500";
      }
    },
    deleteTeam() {
      // delete account
      alert("Are you sure you want to delete this team?");
    },

    saveChanges(teamID) {
      console.log(this.teamID);
      console.log(this.teamName);
      console.log(this.changesArr);
      // get the team id from changesArr
      this.changesArr.forEach((element) => {
        this.teamID = element.teamID;
        console.log(this.teamID);
      });

      // revert last meeting date to 2000-01-01T00:00:00Z
      // this.teamData.lastMeeting = this.teamData.lastMeeting
      //   .split("/")
      //   .reverse()
      //   .join("-");
      // this.teamData.lastMeeting = this.teamData.lastMeeting + "T00:00:00Z";
      // console.log(this.teamData.lastMeeting);

      console.log(this.CVstatus);

      console.log(this.teamData.CVstatus);
      axios.put(
        "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/tracking/team/update/" +
          teamID +
          "/",
        // formData
        {
          teamName: this.teamName,
          teamID: this.teamID,
          status: this.status,
          teamIndividual: this.teamIndividual,
          teamFormationStatus: this.teamData.teamFormationStatus,
          interviewStatus: this.teamData.interviewStatus,
          CVstatus: this.teamData.CVstatus,
          lastMeeting: this.teamData.lastMeeting.split("/").reverse().join("-"),
          // this.teamData.lastMeeting = this.teamData.lastMeeting + "T00:00:00Z";
          // meetingDiscord: this.meetingDiscord,
          // meetingEmail: this.meetingEmail,
          // teamFeedback: this.teamFeedback,
        }
      );
      console.log("SAVED");
    },

    addNewMember() {
      // add new member
      var my_object = {
        mail: this.mail,
        date: this.date,
        adress: this.adress,
        company: this.company,
        fliers: this.fliers,
      };
      this.rowData.push(my_object);

      this.mail = "";
      this.date = "";
      this.adress = "";
      this.company = "";
      this.fliers = "";
    },

    // },

    sendStatusEmail(selectedColor) {
      // send emails to selected users
      if (selectedColor == "red") {
        alert(
          "Are you sure you want to send emails to selected users with status: Red?"
        );
        this.selectedStatus = "red";
      } else if (selectedColor == "yellow") {
        alert(
          "Are you sure you want to send emails to selected users with status: Yellow?"
        );
      } else if (selectedColor == "green") {
        alert(
          "Are you sure you want to send emails to selected users with status: Green?"
        );
      }
    },

    editUser() {
      //show the edit user modal
      this.editUserModal = true;
    },
    tableColor() {
      return this.counter % 2 !== 0 ? `grey` : "white";
    },

    // selectAllCheckboxes() {
    //   // select all checkboxes
    //   this.selectedRows = [];

    //   var checkboxes = document.getElementsByName("checkbox");
    //   for (var checkbox of checkboxes) {
    //     checkbox.checked = true;
    //   }
    // },
  },
};
</script>

<style></style>
