<template>
  <div class="px-8">
    <div class="flex justify-between">
      <div class="flex justify-start">
        <button
          @click="goToLandingPage()"
          class="px-3 text-gray-700 text-xl rounded-xl py-1.5 flex align-baseline justify-center content-center"
        >
          <img src="../../assets/backArrow.png" class="w-5" alt="" />

          Back
        </button>
      </div>

      <div>
        <button
          id="sheetjsexport"
          @click="exportToExcel()"
          class="flex align-middle justify-center bg-custom-blue border-2 border-custom-blue rounded-2xl px-2 py-1.5 hover:shadow-xl duration-300 space-x-4"
        >
          <img src="../../assets/excel.svg" class="w-4" alt="" />
          <p class="text-white font-bold">Export to excel</p>
        </button>
      </div>
    </div>
    <div>
      <div class="flex flex-col">
        <div class="pb-8">
          <ul
            class="justify-center nav nav-tabs flex flex-row flex-wrap list-none border-b-0 pl-0 mb-0 md:mb-4 space-x-4"
            id="tabs-tab"
            role="tablist"
          >
            <li
              class="nav-item duration-300 ease-in-out hover:text-blue-500"
              role="presentation"
              v-bind:class="{
                'text-gray-900 border-b-2  border-gray-200': openTab !== 1,
                'text-custom-blue border-b-2 border-custom-blue': openTab === 1,
              }"
            >
              <a
                class="cursor-pointer px-2 block font-medium text-sm leading-tight my-2"
                v-on:click="toggleTabs(1)"
                >Enrollment data
              </a>
            </li>
            <li
              class="nav-item duration-300 ease-in-out hover:text-blue-500"
              role="presentation"
              v-bind:class="{
                'text-gray-900 border-b-2  border-gray-200': openTab !== 2,
                'text-custom-blue border-b-2 border-custom-blue': openTab === 2,
              }"
            >
              <a
                class="cursor-pointer px-2 block font-medium text-sm leading-tight my-2"
                v-on:click="toggleTabs(2)"
              >
                Performance data</a
              >
            </li>
            <li
              class="nav-item duration-300 ease-in-out hover:text-blue-500"
              role="presentation"
              v-bind:class="{
                'text-gray-900 border-b-2  border-gray-200': openTab !== 3,
                'text-custom-blue border-b-2 border-custom-blue': openTab === 3,
              }"
            >
              <a
                class="cursor-pointer px-2 block font-medium text-sm leading-tight my-2"
                v-on:click="toggleTabs(3)"
              >
                Program data</a
              >
            </li>
          </ul>
        </div>
        <div
          class="flex flex-col justify-center space-y-8 py-10 px-4 md:px-8"
          v-bind:class="{ hidden: openTab !== 1, block: openTab === 1 }"
          id="tabs-tabContent"
        >
          <table id="TableToExport" class="table-auto">
            <thead>
              <tr class="text-left text-white bg-custom-blue">
                <th class="px-4 py-2 text-left">Data</th>
                <th class="px-4 py-2 text-left">Type</th>
                <th class="px-4 py-2 text-right">Sep 22</th>
                <th class="px-4 py-2 text-right">Oct 22</th>
                <th class="px-4 py-2 text-right">Nov 22</th>
              </tr>
            </thead>
            <tbody class="text-left">
              <tr>
                <td class="border px-4 py-2 text-left">Total enrollment</td>
                <td class="border px-4 py-2 text-left">Cumulative</td>
                <td class="border px-4 py-2 text-right">382</td>
                <td class="border px-4 py-2 text-right">436</td>
                <td class="border px-4 py-2 text-right">491</td>
              </tr>
              <tr>
                <td class="border px-4 py-2 text-left">Enrolled individuals</td>
                <td class="border px-4 py-2 text-left">Cumulative</td>
                <td class="border px-4 py-2 text-right">173</td>
                <td class="border px-4 py-2 text-right">213</td>
                <td class="border px-4 py-2 text-right">236</td>
              </tr>
              <tr>
                <td class="border px-4 py-2 text-left">Individuals applied</td>
                <td class="border px-4 py-2 text-left">This month</td>
                <td class="border px-4 py-2 text-right">173</td>
                <td class="border px-4 py-2 text-right">40</td>
                <td class="border px-4 py-2 text-right">23</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class=""
          v-bind:class="{ hidden: openTab !== 2, block: openTab === 2 }"
        >
          <div class="w-full py-8 px-4">
            <!-- <button
              @click="updateMentor()"
              class="py-2 px-14 bg-custom-blue rounded-full flex justify-center items-center text-white"
            >
              <p class="text-white">Save</p>
            </button> -->
            SHEET 2
          </div>
        </div>
        <div
          class="flex flex-col justify-center space-y-8 py-10 px-4 md:px-8"
          v-bind:class="{ hidden: openTab !== 3, block: openTab === 3 }"
          id="tabs-tabContent"
        >
          SHEET 3
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  data() {
    return {
      openTab: 1,
    };
  },
  components: {},

  beforeMount() {},

  methods: {
    exportToExcel() {
      var tableToExport = document.getElementById("TableToExport");
      var html = tableToExport.outerHTML;
      window.open("data:application/vnd.ms-excel," + encodeURIComponent(html));
    },

    goToLandingPage() {
      this.$router.push("/tsLandingPage");
    },

    getSelectedCheckbox() {
      this.selectedServices = [];
      this.servicesArray.forEach((item) => {
        if (this.$refs[item].checked) {
          this.selectedServices.push(item);
        }
      });
    },

    toggleTabs(tabNumber) {
      this.openTab = tabNumber;
    },
  },
};
</script>

<style></style>
