<template>
  <body
    class="bg-bg-blue px-6 lg:px-20 divide-y-2 divide-zinc-300 justify-center overflow-x-hidden"
  >
    <span></span>
    <div class="">
      <div class="p-0 max-w-4xl">
        <p class="text-2xl text-left font-bold md:mt-10 my-3">
          SABAH.incubation
        </p>

        <p class="text-left py-4">
          We aim to establish a competitive entrepreneurial ecosystem to drive
          Azerbaijan’s economic diversification and secure its innovation hub
          status in the region.
        </p>
        <p class="text-left py-4">
          Creating and developing early-stage companies in various sectors of
          the economy through unique learning programs and a sustainable
          ecosystem of young entrepreneurs and industry professionals.
        </p>
        <p class="text-left py-4">
          A section of this content is included in our core curriculum for
          startup teams as a part of SABAH.incubation
        </p>
      </div>

      <div class="flex flex-col lg:flex-row pt-12">
        <div class="w-auto lg:pr-12">
          <articleCategories />
        </div>

        <div class="flex flex-col">
          <div class="pt-8 pb-2 lg:pt-0 lg:pb-8">
            <div
              class="w-full xl:w-full py-4 border-2 border-zinc-300 rounded-xl"
            >
              <SearchBar />
            </div>
          </div>
          <div
            class="border-2 rounded-xl border-zinc-300 flex flex-col justify-center items-center"
          >
            <div class="w-11/12 flex flex-col justify-center items-center py-6">
              <newPosts />
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>
<script>
import SearchBar from "../components/SearchBar.vue";
import articleCategories from "../components/articleCategories.vue";
// import testPost from "../components/testPost.vue";
import newPosts from "../components/newPosts.vue";

export default {
  data() {
    return {
      searchTags: this.searchTags,
    };
  },
  components: {
    articleCategories,
    SearchBar,
    // testPost,
    newPosts,
  },
};
</script>

<style></style>
