<template>
  <body
    class="bg-bg-blue px-6 lg:px-20 divide-y-2 divide-zinc-300 overflow-x-hidden justify-center max-w-max"
  >
    <span></span>
    <div class="flex flex-col lg:flex-row">
      <div class="py-12 w-full lg:w-4/5">
        <!-- book cover image on the left and title on the right -->
        <div class="flex flex-col md:flex-row md:space-x-8">
          <div class="w-full flex flex-row md:flex-col md:w-1/3">
            <div class="w-8/12 md:w-full">
              <img
                :src="post.book_image"
                class="w-8/12 md:w-full flex justify-center shadow-xl hover:shadow-2xl transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-105 hover:bg-indigo-500 duration-300"
              />
            </div>
            <div class="w-full md:w-full text-left md:text-center">
              <p class="text-xl font-bold hover:text-inc-red md:pt-2">
                {{ post.title }}
              </p>
              <div class="flex md:justify-center text-left truncate">
                <p class="text-xl text-gray-500 text-left">by</p>
                <p class="text-white text-left">_</p>
                <p class="text-lg font-medium text-gray-900 text-left">
                  {{ post.author }}
                </p>
              </div>
              <div class="py-8">
                <div class="flex justify-center align-middle items-center">
                  <button
                    @click="downloadPdf(post.book_pdf)"
                    class="border-2 hover:shadow-xl duration-300 border-custom-blue bg-custom-blue rounded-2xl flex px-2"
                  >
                    <img
                      src="../assets/download.png"
                      alt=""
                      class="w-6 h-6 md:w-6 md:h-6 animate-bounce"
                    />
                    <p
                      class="text-white font-medium flex justify-center align-middle items-center"
                    >
                      DOWNLOAD
                    </p>
                  </button>
                </div>
              </div>

              <div class="py-2 block justify-start md:space-x-4 md:hidden">
                <tagsNew :searchTags="post.tags" />
              </div>
            </div>
          </div>
          <div class="w-full h-full">
            <p class="font-bold text-2xl text-left pt-8 pb-4 md:pb-8 md:pt-0">
              About this book
            </p>
            <p
              class="text-md font-italic text-left hover:text-inc-red whitespace-pre-line"
            >
              {{ post.content }}
            </p>
            <div class="py-2 md:flex justify-start space-x-4 hidden">
              <!-- <ul
                class="inline-flex py-2 text-sm sm:text-sm md:text-base lg:text-md xl:text-md"
                v-for="tag in post.tags"
                :key="tag.id"
              >
                <li
                  @click="searchTag(tag)"
                  class="bg-tag-blue cursor-pointer text-xs px-2 rounded w-max sm:text-sm md:text-base lg:text-md xl:text-md hover:bg-custom-blue hover:text-white duration-300"
                >
                  {{ tag }}
                </li>
              </ul> -->

              <tagsNew :searchTags="post.tags" />
            </div>
          </div>
        </div>

        <!-- <div class="py-2 flex justify-start space-x-4">
          <ul
            class="inline-flex py-2 text-sm sm:text-sm md:text-base lg:text-md xl:text-md"
          >
            <li></li>
          </ul>
        </div> -->
        <div class="py-4">
          <!-- <p class="text-left whitespace-pre-line" v-html="post.content"></p> -->
        </div>
        <div class="py-4">
          <p class="cursor-pointer text-left text-sm font-medium text-gray-500">
            <!-- {{ post.source }} -->
          </p>
        </div>
      </div>

      <div class="py-12 lg:pl-12 lg:w-1/2">
        <relatedArticles />
      </div>
    </div>
  </body>
</template>

<script>
import relatedArticles from "../components/relatedArticles.vue";
import tagsNew from "../components/tagsNew.vue";

import axios from "axios";
export default {
  name: "bookDetails",
  data() {
    return {
      post: "",
      posts: this.posts,
    };
  },

  components: {
    relatedArticles,
    tagsNew,
  },

  beforeMount() {
    this.getPostData();
  },
  methods: {
    //when clicking to download button download the post.download pdf file
    downloadPdf() {
      window.open(this.post.book_download_link);
    },

    goToLink(link) {
      window.open(this.post.source, "_blank");
      window.open(link, "_blank");
    },

    getPostData() {
      this.currentURL = window.location.href;
      let counter = 0;
      let i = 0;
      while (counter < 4) {
        i++;
        if (this.currentURL[i] === "/") {
          counter++;
        }
      }
      let str = "";
      if (this.currentURL[this.currentURL.length] === "/")
        str = this.currentURL.slice(i + 1, this.currentURL.length - 1);
      else str = this.currentURL.slice(i + 1, this.currentURL.length);
      axios
        .get(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/post/" +
            str
        )
        .then((response) => {
          this.post = response.data;
          console.log(this.post);
          this.post.tags = this.post.tags.split(",");
        });
    },
  },
};
</script>

<style></style>
