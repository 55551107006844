<template>
  <body
    class="bg-bg-blue px-6 lg:px-20 divide-y-2 divide-zinc-300 overflow-x-hidden justify-center max-w-max"
  >
    <span></span>
    <div class="flex flex-col lg:flex-row">
      <div class="py-12 w-full lg:w-4/5">
        <p class="text-2xl font-bold text-left hover:text-inc-red">
          {{ post.title }}
        </p>
        <p class="text-sm font-medium text-left text-gray-500">
          {{ post.author }}
        </p>
        <div class="py-2 md:flex justify-start space-x-4 hidden">
          <tagsNew :searchTags="post.tags" />
        </div>
        <div class="py-4">
          <p class="text-left whitespace-pre-line" v-html="post.content"></p>
        </div>
        <div class="py-4">
          <p
            class="cursor-pointer text-left text-sm font-medium text-gray-500"
            @click="goToLink(post.source)"
          >
            <!-- setup for mobile -->
            <iframe
              width="560"
              height="315"
              :src="this.source"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </p>
        </div>
      </div>

      <div class="py-12 lg:pl-12 lg:w-1/2">
        <relatedArticles />
      </div>
    </div>
  </body>
</template>

<script>
import relatedArticles from "../components/relatedArticles.vue";
import tagsNew from "../components/tagsNew.vue";

import axios from "axios";
export default {
  data() {
    return {
      post: "",
      posts: this.posts,
      img: [],
      source: "",
    };
  },

  beforeMount() {
    this.getPostData();
  },
  components: {
    relatedArticles,
    tagsNew,
  },

  methods: {
    goToLink(link) {
      window.open(this.post.source, "_blank");
      window.open(link, "_blank");
    },

    getPostData() {
      console.log("getPostData");
      this.currentURL = window.location.href;
      let counter = 0;
      let i = 0;
      while (counter < 4) {
        i++;
        if (this.currentURL[i] === "/") {
          counter++;
        }
      }
      let str = "";
      if (this.currentURL[this.currentURL.length] === "/")
        str = this.currentURL.slice(i + 1, this.currentURL.length - 1);
      else str = this.currentURL.slice(i + 1, this.currentURL.length);
      console.log(str);
      axios
        .get(
          "https://7c7562ad-7bd2-4ed6-9934-sabahincubation-f00b0914dc16q72.com/post/" +
            str
        )
        .then((response) => {
          this.post = response.data;
          this.post.tags = this.post.tags.split(",");
          console.log(this.post.source);

          this.source = this.post.source;
          this.source = this.source.replace("watch?v=", "embed/");
          this.source = this.source.replace("youtu.be/", "youtube.com/embed/");
          this.source = this.source.replace("www.", "");
          this.source = this.source.replace("https://", "https://www.");
          this.source = this.source.replace("http://", "https://www.");
          this.source = this.source.replace(
            "youtube.com/embed/embed/",
            "youtube.com/embed/"
          );

          if (this.post.content.includes("IMG")) {
            console.log(this.post.images);

            //replace post content with new content that includes multiple images
            let content = this.post.content.split("IMG");
            let newContent = "";
            for (let i = 0; i < content.length; i++) {
              newContent += content[i];
              if (i < this.post.images.length) {
                newContent += `<img src="${this.post.images[i].image}" class="w-full h-auto" />`;
              }
            }
            this.post.content = newContent;
          }
        });
    },
  },
};
</script>

<style></style>
