import "./assets/tailwind.css";

import router from "./router";

import { createApp } from "vue";
import App from "./App.vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "flowbite";
import firebase from "firebase/compat/app";
import JwPagination from "jw-vue-pagination";
let app = "";
// app.component("pagePagination", JwPagination);

firebase.auth().onAuthStateChanged(() => {
  // const originalPush = router.prototype.push;
  // router.prototype.push = function push(location) {
  //   return originalPush.call(this, location).catch((err) => err);
  // };
  if (!app) {
    app = createApp(App);
    app.use(Toast);
    app.use(router);
    app.component("pagePagination", JwPagination);
    app.mount("#app");
  }
});

// import "./assets/tailwind.css";
// import firebase from "./utilities/firebase";
// import router from "./router";
// import "flowbite";
// import { createApp } from "vue";
// import App from "./App.vue";

// const app = createApp(App);

// app.use(router);
// app.use(firebase);

// app.mount("#app");
